import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Card, useScrollTrigger, Button, Container, styled, Avatar, Skeleton, Modal } from '@mui/material';
import girl from '../assest/images/girl.webp'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useNavigate, Link } from 'react-router-dom';
import music from '../assest/images/music type.jpg';
import { url } from '../config/config';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { PiGreaterThanLight } from "react-icons/pi";
import TimeAgo from '../pages/recordings/timeStmaps';
import img from '../assest/images/star.png'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { KaraokeTiming } from '../features/addlyric/addLyric';
import FadeLoader from 'react-spinners/FadeLoader';
import { MdOutlineDeleteOutline } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { KaraokeEdit } from '../features/addlyric/addLyric';
import { BiSolidMessageAdd } from "react-icons/bi";
import { IoAlbums } from "react-icons/io5";
import ProfileTimeAgo from './profileTimestamps';
import GroupIcon from '@mui/icons-material/Group';
import { useParams } from 'react-router-dom';
import { Cancel } from '@mui/icons-material';
import Appcard from './app-card';
import { ImAppleinc } from 'react-icons/im';
import image1 from '../assest/images/Bright Color-01.png'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: '10px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: 'rgb(45, 40, 103)',
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: 'rgb(114, 140, 166)',
  fontWeight: 400,
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&.Mui-selected': {
    color: 'rgb(45, 40, 103)',
    fontWeight: 700,
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgb(45, 40, 103)',
  },
}));

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#635ee7',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }),
);

export default function
  OtherProfileTab({ mixSong }) {
  const [songCollab, setSongCollab] = useState([])
  const [albumList,setAlbumList] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch()
  const [pagination, setPagination] = useState(0);
  const [karaokeInd, setKaraokeInd] = useState('')
   const [giftimage,setGiftimage]=useState(false);
   const [selectedImage, setSelectedImage] = useState('');
  const [text, setText] = useState(true)
    const [opens, setOpens] = useState(false);
  const [groups, setGroups] = useState([])
    const [giftall, setGiftAll] = useState([])
  const tokenValue = localStorage.getItem('token')
  const value1 = useSelector((state) => state)
  console.log(value1)
  const open = Boolean(anchorEl);
  const params = useParams();
  const handleOpen = () => setOpens(true);
  const handleCloses = () => setOpens(false);
  const handleOpenimage = (imageURL) => {
    setSelectedImage(imageURL);
    setGiftimage(true);
  };
  const handleCloseimage = () => {
    setGiftimage(false);
    setSelectedImage('');
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      console.log(scrollTop + windowHeight)
      console.log(documentHeight)
      if (Math.round(scrollTop + windowHeight) >= documentHeight - 1) {
        setPagination(prevPagination => prevPagination + 1)
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
 console.log(pagination)
  useEffect(() => {
    console.log('vijay')
    const userSongCollaboration = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = tokenValue !== null ? await axios.get(`${url}/api/SongCollaboration/getSongCollaborationViewProfileByUserID?page=${pagination}`, config) : await axios.get(`${url}/api/SongCollaboration/getSongCollaborationOtherByUserID?page=${pagination}`)
        console.log(res.data.data, 'ProfilePageload')
        if (pagination === 0) {
          setSongCollab(res.data.data,"kesavan")
        } else {
          console.log('pagination')
          setSongCollab((prevValues) => [...prevValues, ...res.data.data])
        }
        console.log(res.data.data)
      } catch (error) {
        console.log(error)
      }
    }
    userSongCollaboration()
  }, [pagination])

  console.log(songCollab, 'songCollab')
  console.log(pagination, 'paginationssss')


  // useEffect( () => {
  //   const userSongCollaboration = async () => {
  //     try{

  //       const config={
  //         headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  //       }
  //       const res = await axios.get(`${url}/api/UserPlayList/getUserPlayListByUserID/`,config)
  //       console.log(res.data.data)
  //       console(res.data.data)
  //     }catch (error){
  //       console.log(error)
  //     }
  //   }
  //   userSongCollaboration()
  // },[])
  const navigate = useNavigate()
  const [value, setValue] = React.useState(0);
  const [giftData, setGiftData] = useState([])

  const findUsersWithMultipleEntries = (arr) => {
    // Create a map to store user data and counts
    const userCounts = {};

    // Iterate over each object in the array
    // arr.forEach(user => {
    //   obj.users.forEach(user => {
    //     if (userCounts[user._id]) {
    //       userCounts[user._id].count++;
    //     } else {
    //       userCounts[user._id] = { ...user, count: 1 };
    //     }
    //   });
    // });

    arr.forEach(user => {
      if (userCounts[user._id]) {
        userCounts[user._id].count++;
      } else {
        userCounts[user._id] = { ...user, count: 1 };
      }
    })

    // Filter users with more than one entry
    const usersWithMultipleEntries = Object.values(userCounts).filter(user => user.count >= 1);

    return usersWithMultipleEntries;
  };


  const result = findUsersWithMultipleEntries(giftData);
  console.log(result, 'count');

  useEffect(() => {

  })
  const [songUpload, setSongUpload] = useState([])
  const [isinvites, setIsinvites] = useState([])
  const [playlist, setPlaylist] = useState([])
  const [channel,setChannel]=useState([])
  const [openi, setOpeni] = useState(false);
  const handleOpeni = () => {
    setOpeni(true); // Open modal
  };

  const handleClosei = () => {
    setOpeni(false); // Close modal
  };
  const getotherGift = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
      const res = await axios.get(`${url}/api/SongCollaborationGift/getOtherGiftsByUserId/${params.id}`, config)
      console.log(res.data.data, 'gift,,,,,,,,,,')
      setGiftData(res.data.data)
    } catch (error) {
      console.log(error)
    }
    try {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
      const res = await axios.get(`${url}/api/SongCollaborationGift/getOtherAllGiftsByUserId/${params.id}`, config)
      console.log(res.data.data, 'giftssssssssss')
      setGiftAll(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        const getCollab = async () => {
          try {
            const config = {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            }
            const res = await axios.get(`${url}/api/SongCollaboration/getSongCollaborationViewProfileByUserID/${params.id}`,config)
            console.log(res.data.data,"otheruser........");
            
            
            if (pagination === 0) {
              setChannel(res.data.data,"john")
            } else {
              console.log('pagination')
              setChannel((prevValues) => [...prevValues, ...res.data.data])
            }
            console.log(res.data.data)

          } catch (error) {
            console.log(error)
          }
        }
        getCollab()
        break;
      case 1:
        const getInvites = async () => {
          console.log(`${params.id}`,'params.id');

          try {
            // const config = {
            //   headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            // }
            const res = await axios.get(`${url}/api/SongCollaboration/getSongCollaborationOtherUserInvitesByUserID/${params.id}`)
            setIsinvites(res.data.data)
            console.log(res.data.data, "invites.............")
            console.log(res.data.data.length, "invites.............")

          } catch (error) {
            console.log(error)
          }
        }
        getInvites()
        break;
      case 4:
        const getKarokeSOng = async () => {
          try {
            const res = await axios.get(`${url}/api/Karaoke/getUserKaraoke/${params.id}`)
            console.log(res.data.data)
            setSongUpload(res.data.data)
          } catch (error) {
            console.log(error)
          }
        }
        getKarokeSOng()
        break;
      case 2:
        const getUserPlaylist = async () => {
          try {
            const res = await axios.get(`${url}/api/UserPlayList/getUserPlayListByUserID/${params.id}`)
            console.log(res.data.data,'playlistDatta')
            setPlaylist(res.data.data)
          } catch (error) {
            console.log(error)
          }
        }
        getUserPlaylist()
        break;
        case 5:
          { const groups = async () => {
            try {
              const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
              }
              const res = await axios.get(`${url}/api/channel/GetOtherChannelByUserID/${params.id}`, config)
              console.log(res.data.data, "Groupssssssssss......")
              setGroups(res.data.data)
            } catch (error) {
              console.log(error)
            }
          }
  
          getotherGift()
           groups()
        }
        break;
      case 3:
        const AlbumList =  async () => {
          try{
            const res = await axios.get(`${url}/api/UserAlbum/getUserOtherAlbumByUserID/${params.id}`)
            setAlbumList(res.data.data)
            console.log(res.data,'albumDatassssssss')
          }catch (error){
            console.log(error)
          }
        }
        AlbumList()
        break;
     
      case 5:
        getotherGift()
    }
  }
  const handleTiming = async (val) => {
    // const karaokeDetails = await songUpload && songUpload[karaokeInd].KaraokeLyrics 
    // const data ={
    //   lyricTiming : karaokeDetails,
    //   lyricColor : songUpload && songUpload[karaokeInd].Color,
    //   karaokeDetails : {
    //     artist : songUpload && songUpload[karaokeInd].Artist,
    //     genres : songUpload && songUpload[karaokeInd].Genre[0],
    //     title : songUpload && songUpload[karaokeInd].Name,
    //     tags : songUpload && songUpload[karaokeInd].Tags[0],
    //     url : songUpload && songUpload[karaokeInd].CoverImageURL
    //   },
    //   karaokeUrl : {
    //     url : songUpload && songUpload[karaokeInd].KaraokeUrl
    //   }
    // }
    // dispatch(KaraokeTiming(data))
    // setAnchorEl(null)
    // localStorage.setItem('KaraokeEdit',songUpload && songUpload[karaokeInd]._id)
    // navigate('/uploads/songdetails')
    // console.log(val)
    console.log(val)
    // try{
    //   const config={
    //     headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    //   }
    //   const res = await axios.delete(`${url}/api/Karaoke/deleteKaraoke/${val}`,config)
    //   console.log(res.data.data)
    // } catch (error) {
    //   console.log(error)
    // }
    handleChange(0, 4)
  }
  console.log(songUpload)
  const handleClick = (event, val) => {
    setAnchorEl(event.currentTarget);
    setKaraokeInd(val)
  };
  console.log(karaokeInd)
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditKaraoke = () => {
    navigate(`/allsong/${songUpload[karaokeInd]._id}`)
    const value = {
      edited: true
    }
    dispatch(KaraokeEdit(value))
    setAnchorEl(null)
  }
  const handleSongInfo = () => {
    setAnchorEl(null);
    navigate(`/allsong/${songUpload[karaokeInd]._id}`)
  }

  console.log(mixSong)
  // mixSong && mixSong.map((val,ind) => (
  //   console.log(val)
  // ))

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', background: 'rgb(255 255 255)' }} variant="fullWidth">
        <Container maxWidth='md'>
          {/* <Tabs  value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab sx={{textTransform:"capitalize"}} label="Recent" {...a11yProps(0)} />
          <Tab sx={{textTransform:"capitalize"}} label="Invites" {...a11yProps(1)} />
          <Tab sx={{textTransform:"capitalize"}} label="Playlists" {...a11yProps(2)} />
          <Tab sx={{textTransform:"capitalize"}} label="Albums" {...a11yProps(3)} />
          <Tab sx={{textTransform:"capitalize"}} label="Karaokes" {...a11yProps(4)} />
          <Tab sx={{textTransform:"capitalize"}} label="Groups" {...a11yProps(5)} />
          <Tab sx={{textTransform:"capitalize"}} label="Gifts" {...a11yProps(6)} />
        </Tabs> */}
          <AntTabs value={value} onChange={handleChange} aria-label="ant example">
            <AntTab label="Channel" {...a11yProps(0)} />
            <AntTab label="Invites" {...a11yProps(1)} />
            <AntTab label="Playlists" {...a11yProps(2)} />
            <AntTab label="Albums" {...a11yProps(3)} />
            <AntTab label="Karaokes" {...a11yProps(4)} />
            <AntTab label="About" {...a11yProps(5)} />
            {/* <AntTab label="About" {...a11yProps(6)} /> */}
          </AntTabs>
        </Container>
      </Box>
      <Box sx={{ background: 'rgb(240 242 244)', height: '100%' }}>
        <Container maxWidth='md'>
          <CustomTabPanel value={value} index={0}>
            <Card sx={{ boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px', borderRadius: '5px' }}>
              {
                channel.length !== 0 ? <Box sx={{ display: "flex", flexWrap: 'wrap', whiteSpace: 'nowrap', padding: '15px' }}>
                  {
                    channel && channel.map((data, index) => (
                     
                      
                      <Box key={index} sx={{ display: "flex", cursor: 'pointer', gap: 1, marginBottom: '15px', padding: '10px' }} onClick={() => {
                        navigate(`/record/${data._id}`, {
                          state: data._id
                        })
                      }}>
                        <Box>
                          <Avatar sx={{ width: '96px', height: '96px', borderRadius: '5px' }} src={data.CoverImageURL} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                          {
                            data.karaokes && data.karaokes.map((val, ind) => (
                              <Box sx={{ width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', color: 'rgb(45, 40, 103)', fontSize: '14px', fontWeight: 700 }}>{val.Name}</Box>
                            ))
                          }
                          {
                            data.users && data.users.map((val, ind)=>(
                              <Box sx={{ width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', color: '#C8CACF', fontSize: '14px', fontWeight: 700 }}>{val.Name}</Box>
                            ))
                          }
                         
                          <Box sx={{ display: "flex", gap: 1, alignItems: "center", marginTop: '5px' }}>
                            <Box sx={{ display: "flex", alignItems: "center", color: "#C8CACF" }} >
                              <Box><PlayArrowIcon sx={{ fontSize: 20, marginTop: '3px' }} /></Box>
                              <Box sx={{ fontSize: 14 }}>{data.NoOfPlay}</Box>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", color: "#C8CACF" }} >
                              <Box><FavoriteIcon sx={{ fontSize: 16, marginTop: '3px' }} /></Box>
                              <Box sx={{ fontSize: 14 }}>{data.NoOfLikes}</Box>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", color: "#C8CACF" }} >
                              <Box><AccessTimeIcon sx={{ fontSize: 16, marginTop: '3px' }} /></Box>
                              <Box sx={{ fontSize: 14 }}><ProfileTimeAgo timestamp={songCollab && data?.createdAt} /></Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))
                  }
                </Box> : <Box sx={{ display: 'flex', justifyContent: 'center', padding: '60px', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                  <Box>
                    <Typography sx={{ fontSize: '12px', fontWeight: 500, color: 'rgb(129, 131, 140)' }}>You have no recordings yet</Typography>
                  </Box>
                  <Box>
                    <Button sx={{ backgroundColor: '#6C2EFF', color: 'white', fontSize: '15px', fontWeight: 700, textTransform: 'none', padding: '10px', marginTop: '10px' }}>Start Singing</Button>
                  </Box>
                </Box>
              }

            </Card>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <Card sx={{ boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px' }}>
              {
                songUpload.length != 0 ? <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', whiteSpace: 'nowrap', textAlign: 'left' }}>
                  {
                    songUpload && songUpload.map((val, ind) => (
                      <Box>
                        <Box sx={{ display: 'flex', cursor: 'pointer', padding: '10px', marginTop: '10px', '&:hover': { background: '#A0C5E8', borderRadius: '5px' } }}>
                          <Box>
                            <img style={{ width: '64px', height: '64px' }} src={val.CoverImageURL} />
                          </Box>
                          <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', height: '100%', paddingLeft: '8px', marginTop: '7px' }}>
                              <Box>
                                <Typography sx={{ color: 'rgb(45, 40, 103)', width: '160px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.Name}</Typography>
                              </Box>
                              <Box>
                                <Typography sx={{ color: 'rgb(170, 173, 180)' }}>vijaykumar</Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box id="basic-button"
                              aria-controls={open ? 'basic-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                              onClick={(e) => { handleClick(e, ind) }}><MoreVertOutlinedIcon style={{ color: 'gray' }} /></Box>
                          </Box>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            <MenuItem onClick={handleSongInfo} sx={{ borderBottom: '2px solid rgb(240, 242, 244)' }}>Karaoke Info</MenuItem>
                          </Menu>
                        </Box>
                      </Box>
                    ))
                  }
                </Box> : <Box sx={{ display: 'flex', justifyContent: 'center', padding: '60px', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                  <Box>
                    <Typography sx={{ fontSize: '12px', fontWeight: 500, color: 'rgb(129, 131, 140)' }}>You have no uploading song yet</Typography>
                  </Box>
                  <Box>
                    <Button sx={{ backgroundColor: '#6C2EFF', color: 'white', width: '165px', height: '32px', fontSize: '15px', fontWeight: 700, textTransform: 'none' }} onClick={() => {
                      navigate('/uploads/songupload')
                    }}>Song upload</Button>
                  </Box>
                </Box>
              }

            </Card>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Card sx={{ boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px' }}>
              {
                playlist && playlist.length !== 0 ? <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: "left", whiteSpace: 'nowrap', flex: 1, textAlign: 'left' }}>
                  {
                    playlist && playlist.map((val, ind) => (
                      <Box onClick={() => {
                        navigate(`/playlist/${val._id}`)
                      }} sx={{ display: 'flex', margin: '10px', padding: '10px', '&:hover': { background: '#A0C5E8', borderRadius: '5px' } }}>
                        <Box>
                          <img style={{ width: '64px', height: '64px' }} src={val.Name === 'Favorites' ? img : val.CoverImageURL} />
                        </Box>
                        <Box>
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', height: '100%', paddingLeft: '8px', marginTop: '7px' }}>
                            <Box>
                              <Typography sx={{ color: 'rgb(45, 40, 103)', width: '160px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.Name}</Typography>
                            </Box>
                            <Box>
                              <Typography sx={{ color: 'rgb(170, 173, 180)' }}>{playlist.length} recording</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))
                  }
                </Box> : <Box sx={{ display: 'flex', padding: '20px', gap: 2 }}>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Box>
                      <Skeleton variant="rectangular" width={64} height={64} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: .5 }}>
                      <Skeleton width={80} height={10} />
                      <Skeleton width={80} height={10} />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Box>
                      <Skeleton variant="rectangular" width={64} height={64} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: .5 }}>
                      <Skeleton width={80} height={10} />
                      <Skeleton width={80} height={10} />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Box>
                      <Skeleton variant="rectangular" width={64} height={64} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: .5, marginTop: '20px' }}>
                      <Skeleton width={80} height={10} />
                      <Skeleton width={80} height={10} />
                    </Box>
                  </Box>
                </Box>
              }

            </Card>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={6}>
            <Card sx={{ boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                <Box sx={{ fontSize: '15px', fontWeight: 700 }}>Gifts</Box>
                <Box sx={{ fontSize: '15px', fontWeight: 700, color: 'rgb(108, 46, 255)' }}>View All</Box>
              </Box>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', whiteSpace: 'nowrap', textAlign: 'left', gap: 1, padding: '10px' }}>
                {
                  result && result.map((data, ind) => (
                    <Box sx={{ padding: '10px', background: 'rgb(240, 242, 244)', borderRadius: '8px' }}>
                      <img style={{ height: '80px', width: '80px' }} src={data.GiftImageURL} />
                      <Box sx={{ padding: '4px', background: 'rgb(255, 255, 255)', color: 'black', textAlign: 'center', opacity: 1, position: 'absolute', borderRadius: '6px', minWidth: '30px', top: 703 }}>{data.count}</Box>
                    </Box>
                  ))
                }
              </Box>
            </Card>
          </CustomTabPanel>
          {/* otherProfile */}
          <CustomTabPanel value={value} index={1}>
            <Card sx={{ boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px', borderRadius: '5px' }}>
              {
                isinvites.length !== 0 ? <Box sx={{ display: "flex", flexWrap: 'wrap', whiteSpace: 'nowrap', padding: '15px' }}>
                  {
                    isinvites && isinvites.map((data, index) => (
                      <Box key={index} sx={{ display: "flex", cursor: 'pointer', gap: 1, marginBottom: '15px', padding: '10px' }} >
                        <Box onClick={() => {
                          navigate(`/record/${data._id}`, {
                            state: data._id
                          })
                        }}>
                          <Avatar sx={{ width: '96px', height: '96px', borderRadius: '5px' }} src={data.CoverImageURL} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          {
                            data.karaokes && data.karaokes.map((val, ind) => (
                              <Box sx={{ width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', color: 'rgb(45, 40, 103)', fontSize: '14px', fontWeight: 700 }}><Box onClick={() => {
                                navigate(`/record/${data._id}`, {
                                  state: data._id
                                })
                              }}>{val.Name}</Box></Box>
                            ))
                          }

                          {
                            data.users && data.users.map((val, ind) => (
                              <Box sx={{ color: 'rgb(170, 173, 180)', fontSize: '14px', fontWeight: 500 }}>{val.Email}</Box>
                            ))
                          }
                          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                            <Box sx={{ display: "flex", alignItems: "center", color: "#C8CACF" }} >
                              <Box><PlayArrowIcon sx={{ fontSize: 20, marginTop: '3px' }} /></Box>
                              <Box sx={{ fontSize: 14 }}>{data.NoOfPlay}</Box>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", color: "#C8CACF" }} >
                              <Box><FavoriteIcon sx={{ fontSize: 16, marginTop: '3px' }} /></Box>
                              <Box sx={{ fontSize: 14 }}>{data.NoOfLikes}</Box>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", color: "#C8CACF" }} >
                              <Box><AccessTimeIcon sx={{ fontSize: 16, marginTop: '3px' }} /></Box>
                              <Box sx={{ fontSize: 14 }}><ProfileTimeAgo timestamp={songCollab && data.createdAt} /></Box>
                            </Box>
                          </Box>
                          
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <Box sx={{ display: "flex", alignItems: "center", color: "white", backgroundColor: "#C8CACF", borderRadius: "4px", width: "70px", height: '30px' }} >
                              <Button onClick={handleOpeni}>Invite</Button>
                            </Box>
                            {
                              data.CollaborationTypeID === "Duet" ? <Box onClick={() => { navigate(`/joinlist/${data._id}/${index}/${data.users[0]?._id}`) }} sx={{ display: 'flex', width: '30px', height: '20px', justifyContent: 'space-between', padding: '5px', background: 'rgb(60 134 247)', borderRadius: '5px' }}>
                                <Box><GroupIcon style={{ color: 'white' }} /></Box>
                                <Box sx={{ color: 'white' }}>{data.NoOfJoin}</Box>
                              </Box> : ''
                            }
                          </Box>
                        </Box>
                      </Box>
                    ))
                  }
                </Box> : <Box sx={{ display: 'flex', textAlign: 'center', padding: '20px', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                  <Box><BiSolidMessageAdd color='rgb(192, 204, 216)' style={{ textAlign: 'center' }} size={40} /></Box>
                  <Box sx={{ color: 'gray' }}>Invite your friends to join collab!</Box>
                  <Button sx={{ color: 'white', background: '#6C2EFF', padding: '10px', textTransform: 'none', marginTop: '10px', fontSize: '15px', fontWeight: 700 }}>Start Singing</Button>
                </Box>
              }
            </Card>
          </CustomTabPanel>
          <Modal open={openi} onClose={handleClosei} aria-labelledby="modal-title" aria-describedby="modal-description">
    <Box
      sx={{
        width: '600px',
        backgroundColor: '#f9f9f9',
        padding: 4,
        margin: 'auto',
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -30%)',
        borderRadius: 2,
        boxShadow: 24,
      }}
    >
      <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
        <Cancel onClick={handleClosei} style={{ cursor: 'pointer' }} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <Box>
          <img src={image1} alt="App Preview" style={{ width: 180, height: 'auto' }} />
        </Box>
        <Box>
          <Typography id="modal-title" variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
            Get the SparkSinger App
          </Typography>
          <Typography id="modal-description" variant="body1" sx={{ mb: 3 }}>
            Sing your favorite songs and immerse yourself in a vibrant community of music lovers with the  SparkSinger app - download it today from your app store!
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
          
            <Appcard icon={<ImAppleinc fontSize="large" />} tittle1="Download on the" tittle2="App Store" />
            <Appcard icon={<ImAppleinc fontSize="large" />} tittle1="Download on the" tittle2="Google Play" />
          </Box>
        </Box>
      </Box>
    </Box>
  </Modal>
          <CustomTabPanel value={value} index={5}>
            <Card
              sx={{
                boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px',
                borderRadius: '5px',
                padding: '15px',
                position: 'relative',
              }}
            >
              {groups.length !== 0 ? (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2,
      }}
    >
      <Box sx={{ fontSize: 15, fontWeight: 'bold', color: 'rgb(45, 40, 103)' }}>
        {groups.length} Group{groups.length > 1 ? 's' : ''}
      </Box>
      <Button
       
        sx={{
          textTransform: 'none',
          fontWeight:"bold",
          color: '#6C2EFF',
          padding: '6px 12px',
          fontSize: { xs: '12px', sm: '14px' },
        }}
        // onClick={handleNextPage}
      >
        View All
      </Button>
    </Box>
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, flexWrap: 'wrap' }}>
      {groups.slice(0, 5).map((data, index) => (
        <Box
          key={index}
          sx={{
            padding: '10px',
            borderRadius: '5px',
            backgroundColor: '#f9f9f9',
            gap: 2,
            textAlign: 'center',
          }}
        >
          <Box
            sx={{ cursor: 'pointer', alignItems: 'center', gap: 2 }}
            onClick={() =>
              navigate(`/groupinfo/${data._id}`, {
                state: data,
              })
            }
          >
            <Avatar
              sx={{
                width: '120px',
                height: '100px',
                borderRadius: '2px',
                mt: 2,
              }}
              src={data.CoverImage}
            />
            <Box
              sx={{
                height: '100%',
                paddingLeft: '8px',
                marginTop: '7px',
                fontSize: 15,
                fontWeight: 'bold',
                color: 'rgb(45, 40, 103)',
              }}
            >
              {data.ChannelName}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  </Box>
) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '60px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: 500, color: 'rgb(129, 131, 140)' }}
                  >
                    does not participate in any groups yet.
                  </Typography>
                </Box>
              )}
            </Card>

            <Box sx={{ mt: 4 }}>
              <Card
                 sx={{
                  boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px',
                  borderRadius: '5px',
                  padding: '15px',
                  position: 'relative',
                }}
              >
                 {giftData.length !== 0 ? (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2,
      }}
    >
      <Box sx={{ fontSize: 15, fontWeight: 'bold', color: 'rgb(45, 40, 103)' }}>
        {giftData.length} Gift{giftData.length > 1 ? 's' : ''}
      </Box>
      <Button
       
        sx={{
          textTransform: 'none',
          fontWeight:"bold",
          color: '#6C2EFF',
          padding: '6px 12px',
          fontSize: { xs: '12px', sm: '14px' },
        }}
        onClick={handleOpen}
      >
        View All
      </Button>
    </Box>
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, flexWrap: 'wrap' }}>
      {giftData.slice(0, 5).map((data, index) => ( // Only take the first 5 items
        <Box
          key={index}
          sx={{
            padding: '10px',
            borderRadius: '5px',
            backgroundColor: '#f9f9f9',
            gap: 2,
            textAlign: 'center',
          }}
        >
          <Box>
            <Avatar
              sx={{
                width: '120px',
                height: '100px',
                borderRadius: '2px',
                mt: 2,
              }}
              src={data.GiftImageURL}
              onClick={() => handleOpenimage(data.GiftImageURL)}
            />
            <Box
              sx={{
                height: '100%',
                paddingLeft: '8px',
                marginTop: '7px',
                fontSize: 15,
                fontWeight: 'bold',
                color: 'rgb(45, 40, 103)',
              }}
            >
              {data.count}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  </Box>
)  : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '60px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: 500, color: 'rgb(129, 131, 140)' }}
                  >
                    
                   does not participate in any Gift yet.
                  </Typography>
                 
                </Box>
              )}
              </Card>
              <Modal 
              open={giftimage}
               onClose={handleCloseimage}
               >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
          }}
        >
          <img
            src={selectedImage}
            alt="Gift"
            style={{
              width: 280,
             
              borderRadius: '8px',
            }}
          />
        </Box>
      </Modal>
              <Modal
                open={opens}
                onClose={handleCloses}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Card sx={{ height: '480px', width: '400px' }}>
                  {/* Header Section */}
                  <Box
  sx={{
    display: 'flex',
    alignItems: 'center', // Ensures vertical alignment
    justifyContent: 'space-between',
    padding: '10px',
  }}
>
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
    <Box
      sx={{
        color: 'rgb(45, 40, 103)',
        fontSize: '17px',
        fontWeight: 700,
      }}
    >
      Gifts
    </Box>
  </Box>
  <Box sx={{ cursor: 'pointer' }}>
    <Cancel
      onClick={handleCloses}
      style={{ fontSize: '30px', color: 'rgb(192, 204, 216)' }}
    />
  </Box>
</Box>

                  {/* Tab Section */}
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderTop: '3px solid #EAE9F0',
                        background: 'rgb(240, 242, 244)',
                      }}
                    >
                      <Box sx={{ marginTop: '15px', marginBottom: '10px' }}>
                        <button
                          onClick={() => setText(true)}
                          style={{
                            width: '130px',
                            height: '32px',
                            background: text ? 'rgb(114, 140, 166)' : 'rgb(255, 255, 255)',
                            color: text ? 'rgb(255, 255, 255)' : 'rgb(114, 140, 166)',
                            borderTopLeftRadius: '3px',
                            borderBottomLeftRadius: '3px',
                            border: 'none',
                            fontSize: '14px',
                            fontWeight: 700,
                            cursor: 'pointer',
                          }}
                        >
                          All Gifts
                        </button>
                        <button
                          onClick={() => setText(false)}
                          style={{
                            width: '130px',
                            height: '32px',
                            background: text ? 'rgb(255, 255, 255)' : 'rgb(114, 140, 166)',
                            color: text ? 'rgb(114, 140, 166)' : 'rgb(255, 255, 255)',
                            borderTopRightRadius: '3px',
                            borderBottomRightRadius: '3px',
                            border: 'none',
                            fontSize: '14px',
                            fontWeight: 700,
                            cursor: 'pointer',
                          }}
                        >
                          Top Gifters
                        </button>
                      </Box>
                    </Box>
                  </Box>

                  {/* Content Section */}
                  <Box sx={{ height: 300, overflowY: 'scroll', width: '100%', }}>
                    {text ? (
                       giftall &&  giftall ? (
                        giftall.map((val, ind) => (
                          <Box 
                              key={ind} 
                              sx={{ 
                                display: 'flex', 
                                alignItems: 'center', // Align items vertically center
                                padding: '10px', 
                                borderBottom: '1px solid #ddd' // Optional separator
                              }}
                            >
                              {/* Avatar */}
                              <Avatar 
                                src={val.CoverImage} 
                                // onClick={() => handleNavigate(val._id)} 
                                sx={{ width: 50, height: 50, marginRight: '10px' ,cursor:'pointer'}} 
                              />
                          
                              {/* Name and Time */}
                              <Box sx={{ flexGrow: 1 }}> 
                                <Typography 
                                  color="rgb(45, 40, 103)" 
                                  fontWeight={700} 
                                  fontSize={16}
                                >
                                  {val.UserName}
                                </Typography>
                                <Typography 
                                  color="rgb(45, 40, 103)" 
                                  fontWeight={400} 
                                  fontSize={10}
                                  sx={{ marginTop: '3px' }}
                                >
                                  <Box sx={{ fontSize: 14 }}>
                                    <ProfileTimeAgo timestamp={val.createdAt} />
                                  </Box>
                                </Typography>
                              </Box>
                          
                              {/* Gift Image */}
                              <Avatar 
                                src={val.GiftImageURL}
                                sx={{ width: 50, height: 50, borderRadius: 0 }} 
                              />
                            </Box>
                        ))
                      ) : (
                        <Typography color="rgb(114, 140, 166)" textAlign="center" sx={{ marginTop: '20px', fontSize: '14px' }}>
                          No Requests Found
                        </Typography>
                      )
                    ) : (
                      giftall &&  giftall ? (
                        giftall &&  giftall.map((val, ind) => (
                            <Box 
                              key={ind} 
                              sx={{ 
                                display: 'flex', 
                                alignItems: 'center', // Align items vertically center
                                padding: '10px', 
                                borderBottom: '1px solid #ddd' // Optional separator
                              }}
                            >
                              {/* Avatar */}
                              <Avatar 
                                src={val.CoverImage} 
                                // onClick={() => handleNavigate(val._id)} 
                                sx={{ width: 50, height: 50, marginRight: '10px',cursor:"pointer" }} 
                              />
                          
                              {/* Name and Time */}
                              <Box sx={{ flexGrow: 1 }}> 
                                <Typography 
                                  color="rgb(45, 40, 103)" 
                                  fontWeight={700} 
                                  fontSize={16}
                                >
                                  {val.UserName}
                                </Typography>
                                <Typography 
                                  color="rgb(45, 40, 103)" 
                                  fontWeight={400} 
                                  fontSize={10}
                                  sx={{ marginTop: '3px' }}
                                >
                                  <Box sx={{ fontSize: 14 }}>
                                    <ProfileTimeAgo timestamp={val.createdAt} />
                                  </Box>
                                </Typography>
                              </Box>
                          
                              {/* Gift Image */}
                              <Avatar 
                                src={val.GiftImageURL}
                                sx={{ width: 50, height: 50, borderRadius: 0 }} 
                              />
                            </Box>
                          ))
                        )  : (
                        <Typography color="rgb(114, 140, 166)" textAlign="center" sx={{ marginTop: '20px', fontSize: '14px' }}>
                          No Invites Found
                        </Typography>
                      )
                    )}
                  </Box>
                </Card>
              </Modal></Box>

          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <Card sx={{ boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px' }}>
              {/* <Box sx={{ display: 'flex', textAlign: 'center', padding: '20px', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Box><IoAlbums color='rgb(192, 204, 216)' style={{ textAlign: 'center' }} size={40} /></Box>
                <Box sx={{ color: 'gray' }}>Craete Your own albums!</Box>
                <Button sx={{ color: 'white', background: '#6C2EFF', padding: '10px', textTransform: 'none', marginTop: '10px', fontSize: '15px', fontWeight: 700 }}>Craete Album</Button>
              </Box> */}
              {
                albumList && albumList.length !== 0 ? <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: "left", whiteSpace: 'nowrap', flex: 1, textAlign: 'left' }}>
                  {
                    albumList && albumList.map((val, ind) => (
                      <Box onClick={() => {
                        navigate(`/playlist/${val._id}`)
                      }} sx={{ display: 'flex', margin: '10px', padding: '10px', '&:hover': { background: '#A0C5E8', borderRadius: '5px' } }}>
                        <Box>
                          <img style={{ width: '64px', height: '64px' }} src={val.Name === 'Favorites' ? img : val.CoverImageURL} />
                        </Box>
                        <Box>
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', height: '100%', paddingLeft: '8px', marginTop: '7px' }}>
                            <Box>
                              <Typography sx={{ color: 'rgb(45, 40, 103)', width: '160px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.Name}</Typography>
                            </Box>
                            <Box>
                              <Typography sx={{ color: 'rgb(170, 173, 180)' }}>1 recording</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))
                  }
                </Box> : <Box sx={{ display: 'flex', padding: '20px', gap: 2 }}>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Box>
                      <Skeleton variant="rectangular" width={64} height={64} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: .5 }}>
                      <Skeleton width={80} height={10} />
                      <Skeleton width={80} height={10} />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Box>
                      <Skeleton variant="rectangular" width={64} height={64} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: .5 }}>
                      <Skeleton width={80} height={10} />
                      <Skeleton width={80} height={10} />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Box>
                      <Skeleton variant="rectangular" width={64} height={64} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: .5, marginTop: '20px' }}>
                      <Skeleton width={80} height={10} />
                      <Skeleton width={80} height={10} />
                    </Box>
                  </Box>
                </Box>
              }
            </Card>
          </CustomTabPanel>
        </Container>
      </Box>

    </Box>
  );
}
