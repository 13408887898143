import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { deepOrange, green } from '@mui/material/colors';
import UploadDropdown from './upload-dropdown';
import { Link } from 'react-router-dom';
import singing from '../assest/images/singing.jpg'
import { jwtDecode } from 'jwt-decode';
export default function BasicMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const userTokenData = localStorage.getItem('token') !== null ? jwtDecode(localStorage.getItem('token')) : ''
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    console.log(localStorage.getItem('token'), 'userTokenData')
    console.log(userTokenData.RoleID,'userTokenData')
    return (
        <div>
            <Box
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ marginTop: "2px", textTransform: "capitalize", display: "flex", cursor: "pointer" }}
            >
                <Typography sx={{fontWeight:"bold",fontSize:17,
                    color: "White",
                    "&:hover": {
                        color: 'green'
                    },
                }}>Uploads</Typography>
                <Box>
                    <KeyboardArrowDown />
                </Box>
            </Box>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                tra
                MenuListProps={{
                    'aria-labelledby': 'basic-button',

                }}
            >
                {
                    UploadDropdown && UploadDropdown.map((data, index) => (
                        <Link style={{ textDecoration: "none" }} to={data.path}>
                            <MenuItem onClick={handleClose}>
                                {data.tittle !== 'Add gift details' ? <Box sx={{
                                    display: "flex", alignItems: "center", gap: "10px", padding: "3px",
                                    color: "black",
                                    "&:hover": {
                                        color: "white", bgcolor: "#2D2867", borderRadius: "5px", padding: "3px"
                                    },
                                }}>
                                    <Box><Stack direction="row" spacing={2}>
                                        <Box>
                                            <Avatar sx={{ bgcolor: "white", width: "60px", height: "60px", borderRadius: "5px", backgroundColor: "#2D2867" }} variant="square">
                                                {data.img}
                                            </Avatar>
                                        </Box>

                                    </Stack></Box>
                                    <Box>
                                        <Box sx={{ fontSize: "14px" }}>{data.tittle}</Box>
                                        <Box sx={{ fontSize: "10px" }}>{data.describe}</Box>
                                    </Box>
                                </Box> : true && userTokenData.RoleID === '66f3ad7cd3369de6e5a26a13' ? <Box sx={{
                                    display: "flex", alignItems: "center", gap: "10px", padding: "3px",
                                    color: "black",
                                    "&:hover": {
                                        color: "white", bgcolor: "#2D2867", borderRadius: "5px", padding: "3px"
                                    },
                                }}>
                                    <Box><Stack direction="row" spacing={2}>
                                        <Box>
                                            <Avatar sx={{ bgcolor: "white", width: "60px", height: "60px", borderRadius: "5px", backgroundColor: "#2D2867" }} variant="square">
                                                {data.img}
                                            </Avatar>
                                        </Box>

                                    </Stack></Box>
                                    <Box>
                                        <Box sx={{ fontSize: "14px" }}>{data.tittle}</Box>
                                        <Box sx={{ fontSize: "10px" }}>{data.describe}</Box>
                                    </Box>
                                </Box> : null}
                            </MenuItem>
                        </Link>
                    ))
                }
            </Menu>
        </div>
    );
}
