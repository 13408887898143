import { Box, Divider, List, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'
import Spark from './spark'

const Guidelines = () => {
  return (
    
    <Box sx={{ padding: 5, backgroundColor: '#F7F7F7', borderRadius: 2 }}>
    <Typography variant="h4" sx={{ marginBottom: 3, fontWeight: 'bold', textAlign: 'center' }}>
      Welcome to the SparkSinger Community!
    </Typography>
    <Typography variant="body1" sx={{ marginBottom: 2 }}>
      Our Community Guidelines are here to ensure everyone enjoys their time on SparkSinger. By following these Guidelines, you can trust the connections you make on SparkSinger.
    </Typography>
    <Typography variant="body1" sx={{ marginBottom: 2 }}>
      Your use of SparkSinger is subject to these Guidelines and our Terms of Service. If you break these Guidelines or our Terms of Service, we may remove your content, permanently disable your account, notify law enforcement, or take legal action. If your account is terminated for violating these Guidelines or the Terms of Service, you cannot use SparkSinger again or create a new account without SparkSinger's permission.
    </Typography>
    
    <Divider sx={{ margin: '20px 0' }} />

    <Typography variant="h5" sx={{ marginBottom: 2 }}>
      What You Should Do:
    </Typography>

    <Typography variant="body1" sx={{ marginBottom: 1 }}>
      <strong>Be Kind and Respectful:</strong> Be polite and respectful when interacting with others. SparkSinger is a global community, so things that seem harmless to you might be viewed differently by someone else. Treat others the way you want to be treated! Help others find great songs or teach them how to make awesome tracks.
    </Typography>

    <Typography variant="body1" sx={{ marginBottom: 1 }}>
      <strong>Have Fun:</strong> SparkSinger is a place for you to enjoy and be yourself, whether you're singing, listening, playing, or collaborating. Remember to have fun and respect others' right to do the same.
    </Typography>

    <Typography variant="body1" sx={{ marginBottom: 1 }}>
      <strong>Keep Your Account Secure:</strong> You are responsible for your account, so keep it safe. If you notice any suspicious activity or security breaches, contact SparkSinger immediately.
    </Typography>

    <Typography variant="body1" sx={{ marginBottom: 1 }}>
      <strong>Stay Connected with Us:</strong> You are a key part of the community! Report any bad behaviour using the app's reporting tools, and reach out to us if you have any questions or concerns.
    </Typography>

    <Typography variant="body1" sx={{ marginBottom: 2 }}>
      For users in India, contact our Grievance Officer:<br />
      Name: Kanagaraj M<br />
      Email: <a href="mailto:raj.mahamuni@softspark.com">raj.mahamuni@softspark.com</a>
    </Typography>

    <Divider sx={{ margin: '20px 0' }} />

    <Typography variant="h5" sx={{ marginBottom: 2 }}>
      What You Should Not Do:
    </Typography>

    <List>
      <ListItem>
        <ListItemText primary="Avoid Bullying or Harassment: Never harass, abuse, or intimidate others." />
      </ListItem>
      <ListItem>
        <ListItemText primary="Do Not Post Objectionable Content: SparkSinger may remove anything considered harmful or unsafe for the community." />
      </ListItem>
      <ListItem>
        <ListItemText primary="Be Yourself: Don’t create fake profiles or impersonate others." />
      </ListItem>
      <ListItem>
        <ListItemText primary="Don’t be a Troll: Avoid being negative or causing trouble in the community." />
      </ListItem>
      <ListItem>
        <ListItemText primary="No Spamming: Don’t use SparkSinger for unsolicited messages or sales." />
      </ListItem>
      <ListItem>
        <ListItemText primary="Protect Your Own and Others’ Privacy: Be careful about sharing sensitive information." />
      </ListItem>
      <ListItem>
        <ListItemText primary="Avoid Brigading: Don’t encourage others to target or flag users." />
      </ListItem>
      <ListItem>
        <ListItemText primary="Respect Intellectual Property Rights: If found violating these rights, your account will be terminated." />
      </ListItem>
      <ListItem>
        <ListItemText primary="Respect SparkSinger’s Policies: Don’t engage in activities that encourage others to break the Guidelines." />
      </ListItem>
      <ListItem>
        <ListItemText primary="No Illegal or Unauthorized Activities: Avoid using SparkSinger for illegal purposes." />
      </ListItem>
    </List>
    
    <Divider sx={{ margin: '20px 0' }} />

    <Typography variant="body1" sx={{ marginTop: 2 }}>
      Thank you for being a part of the SparkSinger Community! We appreciate your cooperation in making it a safe and enjoyable place for everyone.
    </Typography>
  </Box>
  )
}

export default Guidelines