import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import SongGroup from '../../../data/song-group';
import { useNavigate } from 'react-router-dom';
import { url } from '../../../config/config';
import axios from 'axios';
const Grouplist = () => {
    const[groups,setGroups]=useState([]);
    const navigate = useNavigate();
    const handleClick = ()=>{
        navigate("/creategroups")
    }
    useEffect(() => {
        const getInvited = async () => {
            try {
                const config = {
                  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                }
                const res = await axios.get(`${url}/api/channel/GetGlobalChannel`, config)
                console.log(res.data.data, "johnid....")
                setGroups(res.data.data)
              } catch (error) {
                console.log(error)
              }    
       }
       getInvited()
       },[])
  return (
   <Box sx={{display:"grid",gridTemplateColumns:{
    lg:"auto auto auto auto ",md:"repeat(4, auto)",sm:"repeat(3, auto)",xs:"repeat(1, auto)"
   },padding:{
    md:5,sm:0
   },}}>
     <Box>
     <Box 
            sx={{
                width: "250px",
                height: "180px",
                backgroundColor: "#F0F6FF",
                display: "flex",
                borderRadius:3,
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer" // Makes the box look clickable
            }}
            onClick={handleClick} // Attach click handler
        >
            <AddIcon sx={{ width: "48px", height: "48px", color: "#000052" }} />
        </Box>
        <Box>
            <Typography fontSize={16} fontWeight={700} >Create group</Typography>
        </Box>
    </Box>
    {
        groups && groups.map((data,index)=>(
            <Box>
            <Box
            key={index}
             sx={{
                width:"240px",
                height:"180px",
                
                display:"flex",
                textAlign:'start'
            }}onClick={() =>
                navigate(`/groupinfo/${data._id}`, {
                  state: data,
                })
              }><img src={data.CoverImage}></img>
                </Box>
            <Box sx={{mt:2}}>
                <Typography fontSize={14} color='#000052' >{data.ChannelName}</Typography>
            </Box>
            <Box>
                <Typography fontSize={10} color='#AAADB4' >{data.MemberCount}members</Typography>
            </Box>
            <Box>
                <Typography fontSize={10} color='#AAADB4' >{data.type}</Typography>
            </Box>
        </Box>
        ))
    }
 

   
  
   </Box>
  )
}

export default Grouplist