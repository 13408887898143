import { Box, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import image from '../assest/images/Bright Color-01.png';

const Spark = () => {
  const navigate = useNavigate();

  const handlePrivacy = () => navigate("/privacy-policy");
  const handleTerms = () => navigate("/terms-of-service");
  const handleCopyright = () => navigate("/copyright");
  const handleGuidelines = () => navigate("/guidelines");

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
     
      }}
    >
      {/* Main Content */}
      <Box sx={{ flexGrow: 1 }}>
        {/* Your main page content goes here */}
      </Box>

      {/* Footer */}
      <Box
        sx={{
          backgroundColor: '#F0F2F4',
          width: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          color: '#AAADB4',
          height:70,
          textAlign: 'center',
        }}
      >
        {/* Left side: Copyright Text */}
        <Typography sx={{ flex: 1, minWidth: '200px' }}>© 2024 SparkSinger, Inc. All Rights Reserved.</Typography>

        {/* Center: Logo */}
        <Box
          sx={{
            width: { xs: '50%', sm: '15%' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={image}
            alt="Logo"
            style={{ width: '150px', height: 'auto', borderRadius: '8px' }}
          />
        </Box>

        {/* Right side: Links */}
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
          }}
        >
          {[
            { text: 'Privacy Policy', onClick: handlePrivacy },
            { text: 'Terms And Conditions', onClick: handleTerms },
            { text: 'Copyright', onClick: handleCopyright },
            { text: 'Guidelines', onClick: handleGuidelines },
          ].map((item, index) => (
            <Box
              key={index}
              onClick={item.onClick}
              sx={{
                fontSize: 16,
                borderBottom: '1px solid black',
                cursor: 'pointer',
                '&:hover': {
                  color: '#4521B6',
                  borderBottom: '1px solid #4521B6',
                },
              }}
            >
              {item.text}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Spark;
