import { Box, Button, CircularProgress, IconButton, Skeleton, Stack } from '@mui/material'
import React, { useState,useEffect, Suspense } from 'react'

import Card from '@mui/material/Card';
import Coverimage from '../../../assest/images/girl.webp'
import FadeLoader from 'react-spinners/FadeLoader';

import CardMedia from '@mui/material/CardMedia';

import { Navigate, useNavigate } from 'react-router-dom';
import SyncLoader from 'react-spinners/SyncLoader';
import SongbookSkelton from '../../../data/songbook-skelton';
import { PlayCircle } from '@mui/icons-material';
import { GiLoveSong } from 'react-icons/gi';
const SongCard = ({songs,loading}) => {

 const navigate =useNavigate()
 const [pagination,setPagination]= useState(0)
//  songs.map((val,ind) => {
//   console.log(val.metaData)
//  })

useEffect(() => {
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight; 
    if (scrollTop + windowHeight >= documentHeight) {
      console.log('EverOne Incremant')
      setPagination(prevPagination => prevPagination + 1)
    }
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);


  return ( 
    <Box sx={{minHeight:"100vh"}}>
    {
      songs.length !== 0 ?   <><Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "left", whiteSpace: 'nowrap', flex: 1, textAlign: 'left' }}>
          {songs && songs.map((data, index) => (
           
            
            <><Button onClick={() => {
              navigate(`/allsong/${data._id}`, {
                state: data._id
              });
            } } style={{ textDecoration: "none" }}>
              <Box sx={{ margin: 2 }}>
                <Box><Card key={index} sx={{ width: 178, borderRadius: '10px', mb: 1,'&:hover .hover-overlay': { opacity: 1,}, }}>
                  <CardMedia
                    sx={{ height: 178 }}
                    image={data.CoverImageURL ? data.CoverImageURL : Coverimage}
                    title={data.Artist} />
                   <Box
          className="hover-overlay"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 0,
            transition: 'opacity 0.3s',
          }}
        >
          <IconButton
            // onClick={() => onPlay(data)}
            sx={{ color: 'white', fontSize: 50 }}
          >
            
            <GiLoveSong fontSize="inherit" size={80} color={"663FF3"}sx={{color:"#663FF3"}}  />
          </IconButton>
        </Box>
                </Card></Box>
                <Box sx={{ textOverflow: "ellipsis" }}>
                  <Box

                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      width: 178,
                      color: "#2D2867",
                      fontWeight: 800,
                      textAlign: 'left'
                    }}
                  >{data.Name}
                  </Box>
                  <Box sx={{ fontSize: "13px", color: "#2D2867", textAlign: 'left', width: 178, overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.Artist}</Box>
                  <Box sx={{ fontSize: "12px", color: "#585A64", textAlign: 'left', width: 178, overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.users[0]?.Name}</Box>
                </Box>
              </Box></Button>

            </>
          ))}

        </Box><Box sx={{display:'flex',justifyContent:'center'}}>
             {
              loading?<Box>
                {/* <CircularProgress /> */}
            </Box>:null
             }
          </Box></>
         : <Box sx={{display:"flex",flexWrap:"wrap",justifyContent:"left",whiteSpace:'nowrap',flex:1,textAlign:'left',gap:6}}>
              {
                SongbookSkelton && SongbookSkelton.map((val,ind) =>(
                  <Box>
                    <Stack>
                    <Skeleton sx={{marginBottom:'10px'}} animation='wave' variant={`${val.type}`} width={val.width} height={val.Height} />
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    <Skeleton animation='wave'/>
                    </Stack>
                  </Box>
                ))
              }
    </Box> 
         
    }
 
  </Box>
  )
}

export default SongCard