import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { url } from '../config/config';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CancelIcon from '@mui/icons-material/Cancel';
import { useRef, useEffect } from 'react';
import logo from '../assest/images/Bright Color-01.png'
import { Apple, Email, Face, Facebook, Google, Visibility, VisibilityOff, WavingHand } from '@mui/icons-material';
import { CircularProgress, Divider, FormControl, FormHelperText, IconButton, InputAdornment, InputBase, InputLabel, OutlinedInput, TextField } from '@mui/material';
import './Styles/left_Drawer.css'
import { GoogleLogin, useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import { GoAlert } from 'react-icons/go';
import { Bounce } from 'react-toastify';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const Authmodel = ({ open, HandleClose }) => {
  const [email, setemail] = React.useState(null)
  const [loginEmail, setLoginEmail] = React.useState()
  const [password, setpassword] = React.useState(null)
  const [forpass, setForpass] = React.useState(null)
  const [loginpassword, setLoginPassword] = React.useState()
  const [username, setusername] = React.useState(null)
  const [pagination, setPagination] = React.useState(0)
  const [showResetPasswordModal, setShowResetPasswordModal] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState()
  const [resetEmail, setResetEmail] = React.useState("");
  const [reset, setReset] = React.useState("");
  const [show, setshow] = React.useState(true)
  const [loading, setLoading] =React. useState(false);
  const [itshow, setItShow] = React.useState(true)
  const [error, seterror] = React.useState('')
  const [error1, seterror1] = React.useState('')
  const [error2,seterror2]= React.useState('')
  const [passin,setPassin]=React.useState("")
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: "#121212",
    boxShadow: '1px 1px 20px black',
    p: 4,
    borderRadius: 3,
  };
  const [scrollPosition, setPosition] = React.useState({ scrollY: 0 })
  useEffect(() => {
    function updatePosition() {
      setPosition({ scrollY: window.scrollY })
    }

    window.addEventListener('scroll', updatePosition)
    updatePosition()

    return () => window.removeEventListener('scroll', updatePosition)
  }, [])
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev); // Toggle true/false
  };


  // console.log('UserPassword', forpass, 'UserEmail', reset);


  // Prevent focus loss while clicking the visibility icon
  const handleMouseDownPassword = (event) => {
    event.preventDefault(); // Prevent default behavior
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault(); // Prevent default behavior
  };



  const Signup = async () => {
    const Data = {
      Name: username,
      Email: email?.toLowerCase(),
      Password: password,
    };
  
    if (!username || !email || !password) {
      seterror2("All fields are required.");
      return;
    }
  
    try {
      const res = await axios.post(`${url}/api/User/createUser`, Data);
      console.log("Signup successful:", res.data);
      seterror2("");
      window.location.reload();
    } catch (error) {
      console.error("Signup failed:", error.response?.data || error.message);
      // Optionally show error to the user
      alert("Signup failed. Please try again.");
      seterror2(
        error.response?.data?.message || "Signup failed. Please try again."
      );
    }
  };
  const Signin = async () => {
    setLoading(true);
    let valid = false;
    if (!loginEmail) {
      seterror("Email address is required!");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(loginEmail)) {
      seterror("Email address is not valid!");
      valid = false;
    }
    else {
      valid = true;
      seterror("");
    }
    if (!loginpassword) {
      seterror1("Invalid Password");
      valid = false;
    } else {
      valid = true
      seterror1("");
    }

    if (valid) {
      const Data = {
        Name: username,
        Email: loginEmail,
        Password: loginpassword,
      };
  
      try {
        const res = await axios.post(`${url}/api/User/login`, Data);
        console.log("Response:", res.data.data);
  
        // Save token to local storage and reload the page
        window.localStorage.setItem("token", res.data.data);
        window.location.reload();
      } catch (error) {
        if (error.response) {
          // Backend provided an error response
          seterror(error.response.data.message || "Login failed. Please try again.");
        } else if (error.message === "Network Error") {
          seterror("Network error. Please check your connection.");
        } else {
          seterror("An unexpected error occurred.");
        }
      }
      finally {
        setLoading(false); // Hide loading indicator
      }
    }
  }

  const handleGoogleLongin = useGoogleOneTapLogin({
    onSuccess: credentialResponse => {
      console.log(credentialResponse, 'credeintailResponse');
    },
    onError: () => {
      console.log('Login Failed');
    },
  });


  const handleEmailSuccessCall = async (credentialResponse) => {
    console.log(credentialResponse, 'crendiatilaValuesssssss')
    try {
      const Response = await axios.post(`${url}/api/User/google`, { credential: credentialResponse.credential })
      console.log(Response.data)
      window.localStorage.setItem('token', Response.data.token)
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }
  const submitEmail = async () => {
    setLoading(true);
    try {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      };
      const res = await axios.get(`${url}/api/User/sendResetPassword?email=${resetEmail}`, config);
  
      if (res.status === 200) {
        setPassin(res.data, "Email verified successfully");
        // Navigate to the next page (use your routing method, e.g., react-router)
        navigate('/forgatpass');
      } else {
        // Handle unexpected responses
        setPassin('Something went wrong. Please try again.');
      }
    } catch (error) {
      console.error(error);
      // Show an error message to the user
      setPassin('Failed to verify email. Please check your email and try again.');
    }
    finally {
      setLoading(false); // Hide loading indicator
    }
  };
  const handleResetPassword = async () => {
    console.log('UserEmail: ', reset, 'UserPassword :', forpass);

    const formData = new FormData();
    formData.append('UserEmail', reset)
    formData.append('UserPassword', forpass)
    console.log(formData, "john,,,,,")
    try {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
      const res = await axios.post(`${url}/api/User/forgotPassword`, formData, config);
      console.log(res)
      window.location.reload()

    } catch (error) {
      // Handle errors
      console.log(error)

    }
  }

  const handleEmailErrorCall = (error) => {
    console.log(error)
  }

  //   if(inputRef.current !== null){
  //     inputRef.current.addEventListener("keypress",function(event){
  //         if(event.key === 'Enter'){
  //             event.preventDefault();
  //             Signin()
  //         }
  //       })
  //   }
  const [passwordShow, setPasswordShow] = React.useState(false)
  return (
    <Box>
      <Modal
        open={open}
        onClose={HandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          backdropFilter: "blur(5px)",
          backgroundColor: "rgb(0,0,150, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",

        }}

      >
        {
          !show ?
            <Box sx={{ ...style, overflowY: { lg: 'scroll', xl: 'hidden' }, height: { lg: '550px', xl: '800px' } }}>
              <Box sx={{ textAlign: "center", color: 'white' }}>
                <Box onClick={HandleClose} sx={{ display: "flex", justifyContent: "end", cursor: "pointer" }} ><CancelIcon /></Box>
                <Box sx={{ width: "250px", marginLeft: 16, display: 'flex', justifyContent: 'center' }}>
                  <img src={logo} alt="Logo" style={{ maxWidth: '100%', height: 'auto', marginLeft: 100 }} />
                </Box>
                <Box><Typography fontWeight={"bold"} fontFamily={"Cursive"} fontSize={40}>Signup</Typography></Box>
                <Typography fontWeight={"bold"} fontFamily={"Cursive"} fontSize={25}>Welcome to SparkSinger</Typography>
                {error2 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            backgroundColor: "#E91429",
            color: "white",
            mt: 1,
            padding: "8px",
          }}
        >
          <GoAlert style={{ marginRight: "8px", fontSize: 20 }} />
          <span style={{ marginRight: "8px" }}>{error2}</span>
        </Box>
      )}
                <Box sx={{ display: "flex", mt: 2, justifyContent: 'center', position: "relative", flexDirection: "column", gap: 1, marginLeft: 0, textAlign: "center", alignItems: 'center', }}>
                <Box sx={{
                    mb: 1,
                    width: '400px',
                    padding: '3px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: '#202124',
                    border: 1,
                    borderColor: '#6F6F6F',
                    borderRadius: 20,
                    alignItems: "center",
                    cursor: "pointer",
                    '&:hover': {
                      borderStyle: 'solid',
                      borderColor: 'white',
                      border: 1,
                      borderRadius: 20,
                    }
                  }}>
                    <GoogleLogin theme='filled_black' onSuccess={handleEmailSuccessCall} onError={handleEmailErrorCall} />
                  </Box>
                  <Box sx={{
                    mb: 1,
                    width: "400px", height: "40px", border: 1, borderColor: '#6F6F6F', borderRadius: 20, display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer",

                    '&:hover': {
                      borderStyle: 'solid', borderColor: 'white', border: 1, borderRadius: 20, // Hover background color
                    }
                  }}><Facebook sx={{ display: "inline-block", marginRight: 2 }} />Continue with Facebook </Box>
                  <Box sx={{
                    mb: 1,
                    width: "400px", height: "40px", border: 1, borderColor: '#6F6F6F', borderRadius: 20, display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer",
                    '&:hover': {
                      borderStyle: 'solid', borderColor: 'white', border: 1, borderRadius: 20, // Hover background color

                    }
                  }} role="button" // Improve accessibility
                    tabIndex={0}><Apple sx={{ display: "inline-block", marginRight: 2 }} /> Continue with Apple </Box>
                </Box>
                <Divider sx={{ mt: 1, backgroundColor: " #999999" }}></Divider>
                <Box sx={{ textAlign: 'start', width: '400px', margin: '0 auto', mt: 2 }}>
                  <Box sx={{ marginRight: 19, mt: 1 }}><Typography fontSize={14} fontWeight={"bold"} >  Username</Typography></Box>

                  <Box mt={.5}><InputBase sx={{
                    color: 'white',
                    width: '100%',
                    borderRadius: '3px',
                    padding: '10px 20px',
                    border: 1,
                    borderColor: '#6f6f6f',
                    '&:hover': {
                      borderStyle: 'solid',
                      borderColor: 'white',
                    }
                  }}
                    id="outlined-password-input"
                    label='Enter your name'
                    autoComplete="current-password" value={username} onChange={(e) => {
                      setusername(e.target.value)
                    }} name='username' placeholder='Enter your name'></InputBase></Box>
                  <Box sx={{ marginRight: 23, mt: 1.5 }}><Typography fontSize={14} fontWeight={"bold"}>   Email</Typography></Box>
                  <Box mt={.5}>
                    <InputBase sx={{
                      color: 'white',
                      width: '100%',
                      borderRadius: '3px',
                      padding: '10px 20px',
                      border: 1,
                      borderColor: '#6f6f6f',
                      '&:hover': {
                        borderStyle: 'solid',
                        borderColor: 'white',
                      }
                    }}
                      id="outlined-password-input"
                      label='Enter your email'
                      className='textInputFiled'
                      autoComplete="current-password" value={email} onChange={(e) => {
                        setemail(e.target.value)

                      }} name='email' placeholder='Enter your email'></InputBase></Box>
                  <Box sx={{ marginRight: 19, mt: 1.5 }}><Typography fontSize={14} fontWeight={"bold"} >  Password</Typography></Box>
                  <FormControl variant="outlined" fullWidth>
                   
                    <OutlinedInput
                   
                      id="outlined-adornment-password"
                      sx={{
                        backgroundColor: "#121212",
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: "#6F6F6F",
                        },
                        '&:hover': {
                          backgroundColor: "#121212", // Keep the background color on hover
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: "#FFFFFF", // Border color on hover
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: "white", // Text color inside the input field
                        },
                      }}
                      type={passwordShow ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => setpassword(e.target.value)}
                      placeholder="Enter your password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={passwordShow ? 'show the password' : 'hide the password'}
                            onClick={() => setPasswordShow(!passwordShow)}
                            edge="end"
                          >
                            {passwordShow ? <Visibility sx={{ color: "white" }} /> : <VisibilityOff sx={{ color: "white" }} />}
                          </IconButton>
                        </InputAdornment>
                      }
                      
                    />
                  </FormControl>
                  {/* <Box sx={{mt:4}}><Typography fontSize={11} fontWeight={"bold"} mt={2}sx={{color:"red"}}>{error1}</Typography></Box> */}
                </Box>
                <Box mt={1}><Typography fontSize={15}>Already have an account? <span style={{ cursor: "pointer", color: "white", borderBottom: '1px solid white', fontWeight: "bold" }} onClick={() => {
                  setshow(true)
                }}>Log in here</span></Typography></Box>
                <Box mt={1} sx={{ textAlign: 'center', ml: 28 }}>{
                  <Button variant='contained' sx={{
                    height: 30,
                    fontSize: 15,
                    width: 140,
                    borderRadius: 20,
                    backgroundColor: '#1ED760', // green background color
                    color: 'black', // white text color
                    fontFamily: 'alien', // Assuming 'alien' is a custom font you want to use
                    textAlign: 'center', // Center the text horizontally
                    display: 'flex', // Use flexbox to center text vertically
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&:hover': {
                      backgroundColor: '#1EB460', // background color on hover
                      cursor: 'pointer', // pointer cursor on hover
                    }
                  }} onClick={Signup}> SignUp</Button>

                }</Box>
                  <Box mt={1} textAlign={"center"}>
        By selecting one of the Account Creation Options below, you agree to SparkSinger's{' '}
        <a href="/terms-of-service" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'underline' }}>
          Terms of Service
        </a>{' '}
        and{' '}
        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" color='red' style={{ color: 'white', textDecoration: 'underline' }}>
          Privacy Policy
        </a>.
      </Box> 



              </Box>
            </Box> :
            <Box sx={{ ...style, overflowY: { lg: 'scroll', xl: 'hidden' }, height: { lg: '450px', xl: '800px' } }} className='loginModal'>
              <Box sx={{ textAlign: "center", color: "white" }}>
                <Box onClick={HandleClose} sx={{ display: "flex", justifyContent: "end", cursor: "pointer" }} ><CancelIcon /></Box>
                <Box sx={{ width: "250px", marginLeft: 22 }}><img src={logo} /></Box>

                <Box><Typography fontWeight={"bold"} fontFamily={"Cursive"} fontSize={40}>Login</Typography></Box>
                <Typography fontWeight={"bold"} fontFamily={"Cursive"} fontSize={25}>Welcome to SparkSinger</Typography>
                <Box>
                  {(error || error1) && (
                    <Box sx={{ display: 'flex', alignItems: "start", backgroundColor: '#E91429', color: "white", mt: 1, padding: '8px' }}>
                      <GoAlert style={{ marginRight: '8px', fontSize: 20 }} />
                      {error && <span style={{ marginRight: '8px' }}>{error}</span>}

                      {error1 && <span>{error1}</span>}
                    </Box>
                  )}
                </Box>
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  mt: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: "relative",
                  gap: 2,
                  textAlign: "center"
                }}>
                  <Box sx={{
                    mb: 1,
                    width: '400px',
                    padding: '3px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: '#202124',
                    border: 1,
                    borderColor: '#6F6F6F',
                    borderRadius: 20,
                    alignItems: "center",
                    cursor: "pointer",
                    '&:hover': {
                      borderStyle: 'solid',
                      borderColor: 'white',
                      border: 1,
                      borderRadius: 20,
                    }
                  }}>
                    <GoogleLogin theme='filled_black' onSuccess={handleEmailSuccessCall} onError={handleEmailErrorCall} />
                  </Box>
                  <Box sx={{
                    mb: 1,
                    justifyContent: "space-between",
                    width: "400px", height: "40px", border: 1, borderColor: '#6F6F6F', borderRadius: 20, display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer",

                    '&:hover': {
                      borderStyle: 'solid', borderColor: 'white', border: 1, borderRadius: 20,
                    }
                  }}><Facebook sx={{ display: "inline-block", marginRight: 2 }} />
                    <Typography variant="body1">Continue with Facebook</Typography> </Box>
                  <Box sx={{
                    justifyContent: "space-between",
                    mb: 1,
                    width: "400px", height: "40px", border: 1, borderColor: '#6F6F6F', borderRadius: 20, display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer",
                    '&:hover': {
                      borderStyle: 'solid', borderColor: 'white', border: 1, borderRadius: 20,

                    }
                  }} role="button"
                    tabIndex={0}>
                    <Apple sx={{ display: "inline-block", marginRight: 2 }} />
                    <Typography variant="body1">Continue with Apple</Typography> </Box>
                </Box>
                <Box style={{ display: "flex", marginTop: 20, backgroundColor: " #999999" }}><Divider ></Divider></Box>
                <Box sx={{ textAlign: 'start', width: '400px', margin: '0 auto', mt: 3 }}>
                  <Typography fontSize={14} fontWeight={"bold"} > Email</Typography>
                  <InputBase
                    className='SingInIput'
                    sx={{
                      color: 'white',
                      width: '100%',
                      borderRadius: '3px',
                      padding: '10px 20px',
                      border: 1,
                      borderColor: '#6f6f6f',
                      '&:hover': {
                        borderStyle: 'solid',
                        borderColor: 'white',
                      }
                    }}
                    value={loginEmail}
                    onChange={(e) => setLoginEmail(e.target.value)}
                    label="Enter your email"
                    name='email'
                    placeholder='Enter your email'
                  />
                  <Box sx={{ marginRight: 19, mt: 2 }}>
                    <Typography fontSize={14} fontWeight={'bold'}>
                      Password
                    </Typography>
                  </Box>

                  <FormControl variant="outlined" style={{ color: "red" }} fullWidth>
                    {/* <InputLabel  sx={{ color: "#757575", }}>Password</InputLabel> */}
                  
                    <OutlinedInput
                    placeholder='Enter your password'
                      id="outlined-adornment-password"
                      sx={{
                        backgroundColor: "#121212",
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: "#6F6F6F"
                        },
                        '&:hover': {
                          backgroundColor: "#121212",
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: "#FFFFFF",
                          },
                        },
                        '& .MuiInputBase-input': {
                          color: "white",
                        },
                      }}

                      type={passwordShow ? 'text' : 'password'}
                      value={loginpassword}
                      onChange={(e) => setLoginPassword(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          Signin();
                        }
                      }}
                      endAdornment={
                        <InputAdornment position="end" className='textInputFiled'  >
                          <IconButton
                            className='textInputFiled'
                            aria-label={passwordShow ? 'hide the password' : 'show the password'}
                            onClick={() => setPasswordShow(!passwordShow)}
                            edge="end"
                          >
                            {passwordShow ? <Visibility sx={{ color: "white" }} /> : <VisibilityOff sx={{ color: "white" }} />}
                          </IconButton>
                        </InputAdornment>
                      }
                      
                    />
                  </FormControl>


                  {/* Second Error Message */}
                  <Box>
                    {/* <Typography fontSize={11} sx={{ color: 'red', mt: 1 }}>
            {error1}
        </Typography> */}
                  </Box>

                  {/* Sign Up Link */}
                  <Box mt={1} textAlign={'center'}

                    sx={{ cursor: 'pointer', color: 'white' }}
                  ><span
                    style={{
                      cursor: "pointer",
                      color: "white",
                      borderBottom: '1px solid white',
                      fontWeight: 'bold'

                    }}
                    onClick={() => setShowResetPasswordModal(true)}
                  // onClick={() => setshow(false)}
                  >Forgot your password?</span>

                  </Box>
                  <Box mt={1} textAlign={'center'}>
                    <Typography fontSize={15}>
                      Don't have an account?
                      <span
                        style={{
                          cursor: "pointer",
                          color: "white",
                          borderBottom: '1px solid white',
                          fontWeight: 'bold'

                        }}
                        onClick={() => setshow(false)}
                      >Sign up</span>
                    </Typography>
                  </Box>


                  {/* Buttons */}
                  <Box mt={1}>
                    <Box sx={{ display: 'flex', justifyContent: "center", gap: 1 }}>
                      <Box
                        sx={{
                          height: 30,
                          fontSize: 15,
                          width: 140,
                          borderRadius: 20,
                          backgroundColor: '#1ED760',
                          color: 'black',
                          fontFamily: 'alien',
                          textAlign: 'center', // Center the text horizontally
                          display: 'flex', // Use flexbox to center text vertically
                          justifyContent: 'center',
                          alignItems: 'center',
                          '&:hover': {
                            backgroundColor: '#1EB460', // background color on hover
                            cursor: 'pointer', // pointer cursor on hover
                          }
                        }}
                        onClick={HandleClose}
                      >
                       CANCEL
                      </Box>
                      <Button

                        sx={{
                          height: 30,
                          fontSize: 15,
                          width: 140,
                          borderRadius: 20,
                          backgroundColor: '#1ED760', // green background color
                          color: 'black', // white text color
                          fontFamily: 'alien', // Assuming 'alien' is a custom font you want to use
                          textAlign: 'center', // Center the text horizontally
                          display: 'flex', // Use flexbox to center text vertically
                          justifyContent: 'center',
                          alignItems: 'center',
                          '&:hover': {
                            backgroundColor: '#1EB460', // background color on hover
                            cursor: 'pointer', // pointer cursor on hover
                          }
                        }}
                        onClick={Signin}

                      >
                         {loading ? (
        <CircularProgress size={20}   color="inherit" /> // Show loader if loading is true
      ) : (
        'Login' // Show "Login" text if not loading
      )}
                      </Button>
                       
                    </Box>
                  </Box>
                  <Box mt={2} textAlign={"center"}>
        By selecting one of the Account Creation Options below, you agree to SparkSinger's{' '}
        <a href="/terms-of-service" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'underline' }}>
          Terms of Service
        </a>{' '}
        and{' '}
        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" color='red' style={{ color: 'white', textDecoration: 'underline' }}>
          Privacy Policy
        </a>.
      </Box>  
                </Box>


              </Box>
            </Box>

        }

      </Modal>
      <Modal
        open={showResetPasswordModal}
        onClose={() => setShowResetPasswordModal(false)}
        aria-labelledby="reset-password-modal"
        aria-describedby="modal-reset-password-description"
        style={{
          backdropFilter: "blur(5px)",
          backgroundColor: "rgb(0,0,150, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {
          !itshow ?

            <Box sx={{ ...style, width: '400px', padding: 3, textAlign: 'center' }}>

              <Box sx={{ display: 'flex', textAlign: 'start' }}> <IoIosArrowBack color='white' size={25} onClick={() => {
                setItShow(true)
              }} /></Box>

              <Box sx={{ width: '250px', margin: '0 auto' }}>
                <img src={logo} alt="Logo" />
              </Box>

              <Typography
                fontWeight={"bold"}
                fontSize={25}
                color={"white"}
                fontFamily={"Cursive"}
                mt={2}
              >
                Reset Password
              </Typography>
              <Typography
                fontSize={14}
                color={"white"}
                fontFamily={"Cursive"}
                mt={1}
              >
                Enter your email address to receive a password reset link.
              </Typography>

              <Box sx={{ mt: 3 }}>
                <InputBase
                  sx={{
                    color: 'white',
                    width: '100%',
                    borderRadius: '3px',
                    padding: '10px 20px',
                    border: 1,
                    borderColor: '#6f6f6f',
                    '&:hover': {
                      borderColor: 'white',
                    },
                  }}
                  placeholder="Enter your email"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <InputBase
                  sx={{
                    color: 'white',
                    width: '100%',
                    borderRadius: '3px',
                    padding: '10px 20px',
                    border: 1,
                    borderColor: '#6f6f6f',
                    '&:hover': {
                      borderColor: 'white',
                    },
                  }}
                  placeholder="New password"
                  value={reset}
                  onChange={(e) => setReset(e.target.value)}
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <FormControl sx={{ width: '100%' }} variant="outlined">
                  
                  <OutlinedInput
                  placeholder=' Confirm Password'
                    id="outlined-adornment-password"
                    sx={{
                      backgroundColor: '#121212',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#6F6F6F',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#FFFFFF',
                      },
                      '& .MuiInputBase-input': {
                        color: 'white',
                      },
                    }}
                    type={showPassword ? 'text' : 'password'}
                    value={forpass}
                    onChange={(e) => setForpass(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={
                            showPassword ? 'hide the password' : 'display the password'
                          }
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <Visibility sx={{ color: 'white' }} />
                          ) : (
                            <VisibilityOff sx={{ color: 'white' }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  
                  />
                </FormControl>
              </Box>


              <Box sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center', gap: 3, mt: 3 }}>
                <Button
                  variant="outlined"
                  sx={{
                    color: 'white',
                    borderColor: 'white',
                    borderRadius: 20,
                    '&:hover': {
                      borderColor: '#1ED760',
                    },
                  }}
                  onClick={() => setShowResetPasswordModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#1ED760',
                    color: 'black',
                    borderRadius: 20,
                    '&:hover': {
                      backgroundColor: '#1EB460',
                    },
                  }}
                  onClick={handleResetPassword}
                >
                  Submit Now
                </Button>

              </Box>

              <Box sx={{ mt: 2 }}>



              </Box>
            </Box> :
            <Box sx={{ ...style, width: '400px', padding: 3, textAlign: 'center' }}>

              <Box sx={{ width: '250px', margin: '0 auto' }}>
                <img src={logo} alt="Logo" />
              </Box>


              <Typography
                fontWeight={"bold"}
                fontSize={25}
                color={"white"}
                fontFamily={"Cursive"}
                mt={2}
              >
                Reset Password
              </Typography>
              <Typography
                fontSize={14}
                color={"white"}
                fontFamily={"Cursive"}
                mt={1}
              >
                Enter your email address to receive a password reset link.
              </Typography>
              {(passin || passin) && (
                    <Box sx={{ display: 'flex', alignItems: "start", backgroundColor: '#E91429', color: "white", mt: 1, padding: '8px' }}>
                      <GoAlert style={{ marginRight: '8px', fontSize: 20 }} />
                      {passin && <span style={{ marginRight: '8px' }}>{passin}</span>}
                    </Box>
                  )}
              <Box sx={{ mt: 3 }}>
                <InputBase
                  sx={{
                    color: 'white',
                    width: '100%',
                    borderRadius: '3px',
                    padding: '10px 20px',
                    border: 1,
                    borderColor: '#6f6f6f',
                    '&:hover': {
                      borderColor: 'white',
                    },
                  }}
                  placeholder="Enter your email"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                />
              </Box>



              <Box sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center', gap: 3, mt: 3 }}>
                <Button
                  variant="outlined"
                  sx={{
                    color: 'white',
                    borderColor: 'white',
                    borderRadius: 20,
                    '&:hover': {
                      borderColor: '#1ED760',
                    },
                  }}
                  onClick={() => setShowResetPasswordModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={submitEmail}
                  disabled={loading || !resetEmail}
                  sx={{
                    backgroundColor: '#1ED760',
                    color: 'black',
                    borderRadius: 20,
                    '&:hover': {
                      backgroundColor: '#1EB460',
                    },
                  }}
                  >
                    Submit
                    
                    {/* {loading ? <CircularProgress size={24} sx={{ color: 'white' }} onClick={submitEmail}/> : 'Submit'} */}
                  
                </Button>
                       </Box>
            </Box>

        }
      </Modal>

    </Box>



  )

}

export default Authmodel