import React, { useState,useEffect } from 'react'
import BasicCard from '../../../components/card.media'
import { Box, Card, Skeleton, Typography } from '@mui/material'
import Video1 from '../../../assest/videos/TestingDatacheckoutput.mp4'
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FavoriteIcon from '@mui/icons-material/Favorite';
import TopVideo from '../../../data/TopVideo';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { url } from '../../../config/config';
import BasicMenu from '../../../components/basic-menu';
import coverimage from '../../../assest/images/MUSIC.png'
import { IoIosVideocam } from 'react-icons/io';
import homeimg from '../../../assest/images/sparksinger_home.png'
const Cardvideo = () => {
    const userTokanValue = localStorage.getItem('token')
    const [mute, setmute] = useState(true)
    const [genors,setGenors] = useState([])
    const [pagination, setPagination] = useState(0);
    const navigate = useNavigate()

    const handleClick = () => {
        setmute(false)
    }

    const handleClick1 = () => {
        setmute(true)
    }
    const handleExplore = (val) => {
        navigate(`/listen/${val}`)
      }
    useEffect(()=>{
        const userSongCollaborationByGenres = async () => {
          try {
            const config = {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            }
            const res = userTokanValue !== null ?  await axios.get(`${url}/api/SongCollaboration/getSongCollaborationExplore?page=${pagination}`, config) : await axios.get(`${url}/api/SongCollaboration/getSongCollaborationExplore?page=${pagination}`)
            setGenors(res.data.data)
            console.log("uyfguiuytyuideqhiewhiuwhfwhewhfh")
          } catch (error) {
            console.log(error)
          }
        }
        userSongCollaborationByGenres()
      },[])
    
    return (

        <Box>
            <Box sx={{ display: "flex", justifyContent: "center",marginTop:"200px" }}
            >
                <BasicCard
                    video={<video style={{borderRadius:"10px",width:"300px",height:'400px',border: "5px solid white",textAlign:"center",alignItems:"center",cursor:"pointer"}} onClick={() => {
                        { mute ? setmute(false) : setmute(true) }
                    }} autoPlay loop muted={mute}  >
                        <source src={Video1} type="video/mp4" />
                    </video>}
                    mute={mute ? 'Click to unmuted' : 'Click to muted' }
                    icon={mute ? <VolumeOffIcon fontSize='small' onClick={handleClick} sx={{cursor:"pointer"}}/> : <VolumeUpIcon onClick={handleClick1} fontSize='small' />}
                    white='white'
                />
            </Box>
            <Box sx={{mt:10,gap:2}}>
            <Box sx={{display:"flex",flexDirection:"column",textAlign:"center",}}>
                <Box><Typography sx={{fontSize:"32px",fontWeight:"bold"}}>  Top Karaoke Songs With Lyrics</Typography>
                <Typography sx={{fontSize:"25px"}}>Sing trending karaoke songs</Typography></Box>
                <Box sx={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}> 
                    <Card sx={{background: "rgba( 255, 255, 255, 0.25 )", margin: 2, boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px',width:'1037px',height:'300px'}}>
                <Box className='explore-card'  sx={{ display: "flex", flexDirection: "row", overflowX: "scroll", padding: '5px', paddingTop: '10px',xl:"600px",lg:"500px",md:"400px",xs:"100%" }}>
              {
                genors.length !== 0 ? <>
                  {
                    genors.length && genors.map((data, index) => (
                      <Box>
                        <Button onClick={() => {
                          navigate(`/record/${data._id}`, {
                            state: { param1: genors, param2: index }
                          })
                        }} style={{ textDecoration: "none" }}>
                          {
                            data.karaokes && data.karaokes.map((val, ind) => (
                              <>
                               <Box sx={{ position: "relative", textAlign: "center", width: "240px", height: '279px', backgroundImage: `url(${data.CoverImageURL?data.CoverImageURL:coverimage})`, borderRadius: '5px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' ,  "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.6)", // Adds a dark overlay on hover
      backgroundBlendMode: "overlay", // Blend the overlay with the image
    },
    "&:hover .play-icon": {
      display: "flex", // Make the play icon visible on hover
    },}}>
                                <Box
    sx={{
      display: "none", // Initially hide the play icon
      position: "absolute",
      top: "85%",
      left: "83%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#673EF7",
      borderRadius: "50%",
      padding: "5px",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      zIndex: 2,
    }}
    className="play-icon"
  >
    <PlayArrowIcon style={{ color: "white", fontSize: "40px" }} />
  </Box>
                                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                    {
                                      data.PostType === "Video" || "Animation" ? <Box sx={{ display: 'flex', justifyContent: 'end', padding: '5px' }}>
                                        
                                    
                                        
                                      </Box> : ''
                                    }
                                    <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                      <Box sx={{ color: 'rgb(255, 255, 255)', fontWeight: 900, textTransform: "none", fontSize: "14px", textAlign: 'left', paddingLeft: '3px', paddingTop: 0, paddingBottom: 0 }}>
                                        {data === 0 ? 'short-sochege-tumhe-pyar-kre-ke-nhi' : `${val.Name}`}
                                      </Box>
                                      {
                                        data.users && data.users.map((userData, userDataInd) => (
                                          <Box sx={{ display: 'flex', gap: .3, padding: '5px', paddingTop: 0, paddingBottom: 0 }}>
                                            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', color: "rgb(192, 204, 216)", fontSize: "14px", fontWeight: 400, whiteSpace: 'nowrap', textTransform: 'none' }}>
                                              {data === 0 ? 'ddddd' : `${userData.Name}`}
                                            </Box>
                                            {
                                              data.CollaborationTypeID === 'Duet' ? <Box sx={{ color: 'rgb(192, 204, 216)', fontSize: '12px', fontWeight: 400 }}>+ 1</Box> : ''
                                            }
                                          </Box>

                                        ))
                                      }
                                      <Box sx={{ display: 'flex', alignContent: 'left', justifyContent: 'flex-start', paddingBottom: '5px' }}>
                                        <Box sx={{ display: "flex", justifyContent: "space-evenly", color: "#AAADB4", fontSize: "10px", gap: 1 }}>
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>

                                            <Box><PlayArrowIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfPlay}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><FavoriteIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfLikes}</Box>
                                          </Box>.
                                          {/* <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><RedeemIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfGifts}</Box>
                                          </Box> */}
                                          

                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            ))
                          }

                        </Button>
                      </Box>
                    ))
                  }
                </> : <Box sx={{ display: 'flex', gap: 4 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                </Box>
                
              }
            </Box>
            </Card>
                </Box>

                <Box></Box>
            </Box>
            <Box sx={{display:"flex",flexDirection:"column",textAlign:"center",}} >
                <Box><Typography sx={{fontSize:"32px",fontWeight:"bold"}}>Fun And Easy Karaoke</Typography>
                <Typography sx={{fontSize:"25px"}}>Popular karaoke songs</Typography></Box>
                <Box sx={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}> 
                <Card sx={{ background: "rgba( 255, 255, 255, 0.25 )",margin: 2, boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px',width:'1037px',height:'300px'}}>
                <Box className='explore-card'  sx={{ display: "flex", flexDirection: "row", overflowX: "scroll", padding: '5px', paddingTop: '10px' }}>
              {
                genors.length !== 0 ? <>
                  {
                    genors.length && genors.map((data, index) => (
                      <Box>
                        <Button onClick={() => {
                          navigate(`/record/${data._id}`, {
                            state: { param1: genors, param2: index }
                          })
                        }} style={{ textDecoration: "none" }}>
                          {
                            data.karaokes && data.karaokes.map((val, ind) => (
                              <>
                               <Box sx={{ position: "relative", textAlign: "center", width: "240px", height: '279px', backgroundImage: `url(${data.CoverImageURL?data.CoverImageURL:coverimage})`, borderRadius: '5px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' ,  "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.6)", // Adds a dark overlay on hover
      backgroundBlendMode: "overlay", // Blend the overlay with the image
    },
    "&:hover .play-icon": {
      display: "flex", // Make the play icon visible on hover
    },}}>
                                <Box
    sx={{
      display: "none", // Initially hide the play icon
      position: "absolute",
      top: "85%",
      left: "83%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#673EF7",
      borderRadius: "50%",
      padding: "5px",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      zIndex: 2,
    }}
    className="play-icon"
  >
    <PlayArrowIcon style={{ color: "white", fontSize: "40px" }} />
  </Box>
                                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                    {
                                      data.PostType === "Video" || "Animation" ? <Box sx={{ display: 'flex', justifyContent: 'end', padding: '5px' }}>
                                        
                                    
                                        
                                      </Box> : ''
                                    }
                                    <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                      <Box sx={{ color: 'rgb(255, 255, 255)', fontWeight: 900, textTransform: "none", fontSize: "14px", textAlign: 'left', paddingLeft: '3px', paddingTop: 0, paddingBottom: 0 }}>
                                        {data === 0 ? 'short-sochege-tumhe-pyar-kre-ke-nhi' : `${val.Name}`}
                                      </Box>
                                      {
                                        data.users && data.users.map((userData, userDataInd) => (
                                          <Box sx={{ display: 'flex', gap: .3, padding: '5px', paddingTop: 0, paddingBottom: 0 }}>
                                            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', color: "rgb(192, 204, 216)", fontSize: "14px", fontWeight: 400, whiteSpace: 'nowrap', textTransform: 'none' }}>
                                              {data === 0 ? 'ddddd' : `${userData.Name}`}
                                            </Box>
                                            {
                                              data.CollaborationTypeID === 'Duet' ? <Box sx={{ color: 'rgb(192, 204, 216)', fontSize: '12px', fontWeight: 400 }}>+ 1</Box> : ''
                                            }
                                          </Box>

                                        ))
                                      }
                                      <Box sx={{ display: 'flex', alignContent: 'left', justifyContent: 'flex-start', paddingBottom: '5px' }}>
                                        <Box sx={{ display: "flex", justifyContent: "space-evenly", color: "#AAADB4", fontSize: "10px", gap: 1 }}>
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>

                                            <Box><PlayArrowIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfPlay}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><FavoriteIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfLikes}</Box>
                                          </Box>.
                                          {/* <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><RedeemIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfGifts}</Box>
                                          </Box> */}
                                          

                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            ))
                          }

                        </Button>
                      </Box>
                    ))
                  }
                </> : <Box sx={{ display: 'flex', gap: 4 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                </Box>
                
              }
            </Box>
            </Card>
                </Box>
                
                <Box> <Link to='explore'><Button variant="contained" sx={{width:"30%",marginTop:4,backgroundColor:"white",color:"blue",fontWeight:"900"}}>Explore more </Button></Link></Box>
            </Box></Box>
            
        </Box>
    )
}

export default Cardvideo