import { Box, Divider, IconButton, TextField, Typography , Modal, Container, Skeleton, Card, Badge} from '@mui/material'
import React, { useEffect, useState } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { FaEdit } from "react-icons/fa";
import { FaShare } from "react-icons/fa";
import { IoIosContacts } from "react-icons/io";
import BasicTabs from '../../../components/profile-tabs';
import TransitionsModal from '../../../components/model';
import { Cancel, MoreHoriz, ThumbUp } from '@mui/icons-material';
import FollowTab from '../../../components/follow-tabs';
import { IoIosContact } from "react-icons/io";
import { IoMdCheckmark } from "react-icons/io";
import DragDropFileUpload from '../../../components/drag.drop-file4';
import { FaFacebookF } from "react-icons/fa";
import { ImWhatsapp } from "react-icons/im";
import { FaTwitter } from "react-icons/fa";
import { CiLink } from "react-icons/ci";
import { MdEmail } from "react-icons/md";
import { IoIosMore } from "react-icons/io";
import { jwtDecode } from "jwt-decode";
import axios from 'axios';
import { url } from '../../../config/config';
import girl from '../../../assest/images/girl2.jpg';
import img from '../../../assest/images/03d82d29-8fa2-44ac-b34e-353358b62ee9.jpeg';
import FadeLoader from 'react-spinners/FadeLoader';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import FacebookIcon from '@mui/icons-material/Facebook';
import { FaExpand } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import CloseIcon from '@mui/icons-material/Close';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { AiOutlineFullscreen } from "react-icons/ai";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OtherProfileTab from '../../../components/profile-tab1';
import { useNavigate, useParams } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import { IoFlagSharp } from "react-icons/io5";
import { BsFillFlagFill } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import songesss from "../../../assest/images/premiumIcon.png"
import './popup.css';
import Errorpage from '../../../error-page/error-page';
import { BiSolidMessageRounded } from 'react-icons/bi';
import { FaWhatsapp } from 'react-icons/fa6';
import { PiShareFatLight } from 'react-icons/pi';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height:'100%',
  border: '2px solid #000',
  boxShadow: 24,
  p:5
};
const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  width:300,
  borderRadius : '5px'
};
const OtherProfileHeader = ({func}) => {
  const [value, setValue] = React.useState('1');
  const [uploadImage,setUploadImage] = useState('')
  const [open,setOpen] = useState(false)
  const [follow,setFollow] = useState(false)
  const [text,setText] = useState(true)
  const [action,setAction] = useState(0)
  const [followerShow,setFollowerShow] = useState(true)
  const [blockModal,setBlockModal] = useState(false);
  const userTokanValue = localStorage.getItem('token');
  const navigate= useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleFileUpload = (file) => {
    setUploadImage(file)
  };
  const params = useParams();
  console.log(params)
const[profile,setprofile]=useState([])
const[mixSong,setmixSong]=useState([])
  // useEffect(()=>{
  //  setTimeout(() => {
  //   const GetProfile=async()=>{
  //     let token =  window.localStorage.getItem('token')
  //     try {
  //       const res = await axios.get(`http://localhost:4000/profile?page=1`);
      
  //       console.log(res.data)
  //   } catch (error) {
  //       // Handle errors
  //       console.log(error)
  //   }
  //   }
  //   GetProfile()
  //  }, 1000);
  // },[])
  // useEffect(()=>{
  //   setTimeout(() => {
  //    const GetProfile=async()=>{
     
      
  //     try {
  //       let token =  window.localStorage.getItem('token')
  //       const decoded = jwtDecode(token);
  //       const res = await axios.get(`${url}/getmixedsongs/${decoded.userId}`);
      
  //       setmixSong(res.data.data)
  //       console.log(res.data.data)
  //   } catch (error) {  
  //   }
  //   //    
  //    }
  //    GetProfile()
  //   }, 1000);
  //  },[])
  const [otherUser,setOtherUser] = useState([])
  const [userEmail,setUserEmail] = useState([])
  useEffect(() => {
    console.log('callinggggggggg')
    const otherUserDetail = async () => {
        try{
          const config={
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          }
          const id = await params.id
            const res = userTokanValue !== null ? await axios.get(`${url}/api/User/getUserByUserId/${id}`,config) : await axios.get(`${url}/api/User/getUserOtherByUserId/${id}`)
            console.log(res.data.data,'other data')
            setOtherUser(res.data.data)
            const email = await res.data?.data[0].Email;
        setUserEmail(email.split('@'))
           } catch(error){
            console.log(error)
           }
    }
    otherUserDetail()
  },[params.id])
  console.log(otherUser,'otherrUsrrdetailssss')
  
  const [userProfileData,setUserProfileData] = useState([])
  const[followingDetails,setFollowingDetails] = useState([])
  const userTokenData = localStorage.getItem('token') !== null ? jwtDecode(localStorage.getItem('token')) : '';
  console.log(followingDetails)
  const [followingCount,setFollowingCount] = useState('')
  // const [followerCount,setFollo] 
 useEffect(() => {
  const getFollowingData = async () => {
    try{
      const config={
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
     const res = userTokanValue !== null  ? await axios.get(`${url}/api/Following/getUserFollowingByUserID/${params.id}`,config) : await axios.get(`${url}/api/Following/getUserFollowingOtherByUserID/${params.id}`)
     console.log(res.data.data)
     setFollowingDetails(res.data.data)
     console.log(res.data.data,'setFollowingDetails');
     
    } catch(error){
     console.log(error)
    }
 }
 getFollowingData()
 },[action,text])
 console.log(followingDetails)
 const[followerDetails,setFollowerDetails] = useState([])
 useEffect(() => {
  const getFollowersData = async () => {
    try{
      const config={
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
     const res = userTokanValue !== null  ? await axios.get(`${url}/api/Following/getUserFollowersByUserID/${params.id}`,config) : await axios.get(`${url}/api/Following/getUserFollowersOtherByUserID/${params.id}`)
     console.log(res.data.data)
     setFollowerDetails(res.data.data)
    } catch(error){
     console.log(error)
    }
 }
 getFollowersData()
 },[action,text])
  //  useEffect(() => {
  //   const GetProfileData = async () => {
  //     try{
  //       const config={
  //         headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  //       }
  //       const res = await axios.get(`${url}/api/User/getUserById/${localStorage.getItem('token')}`,config)
  //       setUserProfileData(res.data.data)
  //       console.log(res.data.data)
  //     }catch (error){
  //        console.log(error)
  //     }
  //   }
  //   GetProfileData()
  //  },[ ])
  const [count,setCount] = useState([])
  
  useEffect(() => {
    const GetProfileData = async () => {
      try{
        const config={
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/songcollaboration/getOtherSongCollaborationCount/${params.id}`,config)
        console.log(res.data.data,'songcollaborationCount')
        setCount(res.data.data)
      }catch (error){
         console.log(error)
      }
    }
    GetProfileData()
   },[])
  
   const [bio,setBio] = useState('')
const Recording_Model=()=>{
  return(
    <Box sx={{textAlign:"center"}}>
      <Box><Typography fontSize={20} fontWeight={700}>Recordings Count</Typography></Box>
      <Box><Typography fontSize={10}>The recordings count represents the total number of recordings you’ve created on Smule. This count may not reflect the number of recordings deleted by you or other users you’ve recorded with.</Typography></Box>
       <Box><Button variant='contained'>ok</Button></Box>
    </Box>
  )
}
console.log(followingDetails,followerDetails,'detailssssss')
const handleFollwing  = async (id) => {
    console.log(id,'dataidForFollowing')
    setAction((prevState) => prevState + 1)
    try{
      const config={
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
      const request ={
        Name : '',
        FollowingUserID : id
      }
      const res = await axios.post(`${url}/api/Following/createFollowing`,request,config)
      console.log(res.data.data,'createFollowingWorking')
    }catch(error){
      console.log(error)
    }
}

const handleUnfollow = async (id) => {
  setAction((prevState) => prevState + 1)
  try{
    const config={
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }
    const res = await axios.delete(`${url}/api/Following/deleteFollowing/${id}`,config)
    console.log(res.data.data)
  }catch (error){
      console.log(error)
  }
  console.log(id)
}


const handleNavigate =(UserId) => {

console.log(UserId, 'User------------Id');
navigate(`/otherProfile/${UserId}`)

}

const Folowing_Model=()=>{

 
    
  return(
   <Box>
<Card sx={{height:'400px',width:'400px',}}>
<Box sx={{display:'flex',justifyContent:'space-between',padding:'10px'}}>
          <Box sx={{color:'rgb(45, 40, 103)',fontSize:'17px',fontWeight:700,marginTop:'10px'}}>{otherUser && otherUser[0]?.Name !== '' ? otherUser[0]?.Name  : otherUser[0]?.Email }</Box>
        < Box>
        
        </Box>
      </Box>  
      <Box>
        <Box sx={{display:'flex',textAlign:'center',alignItems:'center',justifyContent:'center',borderTop:'3px solid #EAE9F0',background:'rgb(240, 242, 244)'}}>
          <Box sx={{marginTop:'15px',marginBottom:'10px'}}>
            <button  onClick={() => {setText(true)}} style={{width:'130px',height:'32px',background: text ? 'rgb(114, 140, 166)' :'rgb(255, 255, 255)',color: text ? 'rgb(255, 255, 255)' : 'rgb(114, 140, 166)',borderTopLeftRadius:'3px',borderBottomLeftRadius:'3px',border:'none',fontSize:'14px',fontWeight:700,cursor:'pointer'}}>{followingDetails?.length} Following</button>
            <button onClick={() => {setText(false)}}  style={{width:'130px',height:'32px',background:text ? 'rgb(255, 255, 255)' : 'rgb(114, 140, 166)',color: text ? 'rgb(114, 140, 166)' : 'rgb(255, 255, 255)',borderTopRightRadius:'3px',borderBottomRightRadius:'3px',border:'none',fontSize:'14px',fontWeight:700,cursor:'pointer'}}>{followerDetails?.length} Followers</button>
          </Box>
        </Box>
      </Box>
      {
        text ?   <Box sx={{height:'100%',overflow:'scroll',width:'100%'}}>
          <Box sx={{marginBottom:'90px'}}>
          {
          followingDetails && followingDetails.map((val,ind) => (
            <Box sx={{display:'flex',justifyContent:'space-between',cursor:'pointer'}}>
                <Box sx={{display:'flex',padding:'15px',gap:1}} >
                  <Box>
                    
                  <Badge                                
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                       isPremiumActive && ( // Render only if isPremiumActive is true
                                        <Avatar
                                            sx={{ bgcolor: 'red', width: 20, height: 20, fontSize: 10, border: '2px solid white', }}
                                            alt="Remy Sharp"
                                            src={songesss}
                                        >
                                          
                                        </Avatar>
                                          )
                                    }
                                >
                  <Avatar src={val.userData && val.userData[0]?.ProfilePhoto} onClick={() => handleNavigate(val.userData[0]._id)} sx={{width:'45px',height:'45px',borderRadius:'50%'}}/></Badge>
                  </Box>
                    <Box sx={{display:'flex',gap:.3,flexDirection:'column'}}>
                      <Box sx={{fontSize:'14px',fontWeight:700,color:'rgb(45, 40, 103)',overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "140px", // Adjust width as needed
        minWidth: "50px",
        flexShrink: 1,}} onClick={() => handleNavigate(val.userData[0]._id)}>{val.userData[0]?.Name !== ''? val.userData[0]?.Name  : val.userData[0].Email.split("@")[0]}</Box>
                      <Box sx={{fontSize:'14px',fontWeight:400,color:'rgb(114, 140, 166)',width:'160px',overflow:'hidden',textOverflow:'ellipsis'}}>{val.userData[0]?.Email}</Box>
                  </Box>
                </Box>
                {
                  val.userData[0]?._id !== userTokenData.userId &&  <Box sx={{padding:'20px'}}>
                  {
                val.isFollow && true ?   <Box onClick={() => {handleUnfollow(val.FollowingUserID)}}><Button size='small' sx={{fontSize:16,backgroundColor:'rgb(228, 233, 238)',color:'rgb(114, 140, 166)',boxShadow:'rgba(31, 32, 33, 0.1) 0px 2px 8px',fontWeight:700,textTransform:'capitalize'}}  startIcon={<IoMdCheckmark size={14}/>}>
                Following
               </Button></Box> : <Box ><Button size='small' sx={{width:'108px',fontSize:16,backgroundColor:'rgb(108, 46, 255)',color:'white',boxShadow:'rgba(31, 32, 33, 0.1) 0px 2px 8px',fontWeight:700,textTransform:'capitalize'}} variant="contained" startIcon={<GoPlus fill='white' size={18}/>} onClick={() => {handleFollwing(val.FollowingUserID)}}>
               Follow
               </Button></Box>
              }
                  </Box>
                }
            </Box>
          ))
        }
          </Box>
      </Box> : <Box sx={{overflowY:'scroll',height:'100%'}}>
        <Box sx={{marginBottom:'90px'}}>
        {
          followerDetails && followerDetails.map((val,ind) => (
            <Box sx={{display:'flex',justifyContent:'space-between'}} >
              <Box sx={{display:'flex',gap:2,padding:'10px'}}>
                <Box>
                <Badge                                
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                       isPremiumActive && ( // Render only if isPremiumActive is true
                                        <Avatar
                                            sx={{ bgcolor: 'red', width: 20, height: 20, fontSize: 10, border: '2px solid white', }}
                                            alt="Remy Sharp"
                                            src={songesss}
                                        >
                                          
                                        </Avatar>
                                          )
                                    }
                                >
                  <Avatar sx={{width:'45px',height:'45px',borderRadius:'50%',cursor:"pointer"}} onClick={() => handleNavigate(val.userData[0]._id) }src={val.userData && val.userData[0]?.ProfilePhoto}/></Badge>
                </Box>
                <Box sx={{display:'flex',flexDirection:'column',marginTop:'5px'}}>
                  <Box sx={{fontSize:'14px',fontWeight:700,color:'rgb(45, 40, 103)',overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "140px", // Adjust width as needed
        minWidth: "50px",
        flexShrink: 1,}}>{val.userData && val.userData[0]?.Name !== '' ? val.userData[0]?.Name :val.userData[0].Email.split("@")[0] }</Box>
                  <Box sx={{fontSize:'14px',fontWeight:400,color:'rgb(114, 140, 166)',width:'160px',overflow:'hidden',textOverflow:'ellipsis'}}>{val.userData && val.userData[0]?.Email}</Box>
                </Box>
              </Box>
              {
                  val.userData[0]._id !== userTokenData.userId && 
                  <Box sx={{padding:'20px'}}>
                  {
                  val.isFollow && true ?   <Box onClick={() => {handleUnfollow(val.User_ID)}}><Button size='small' sx={{fontSize:16,backgroundColor:'rgb(228, 233, 238)',color:'rgb(114, 140, 166)',boxShadow:'rgba(31, 32, 33, 0.1) 0px 2px 8px',fontWeight:700,textTransform:'capitalize'}}  startIcon={<IoMdCheckmark size={14}/>}>
                  Following
                 </Button></Box> : <Box ><Button size='small' sx={{width:'108px',fontSize:16,backgroundColor:'rgb(108, 46, 255)',color:'white',boxShadow:'rgba(31, 32, 33, 0.1) 0px 2px 8px',fontWeight:700,textTransform:'capitalize'}} variant="contained" startIcon={<GoPlus fill='white' size={18}/>} onClick={() => {handleFollwing(val.User_ID)}}>
                 Follow
                 </Button></Box>
                }
                  </Box>
                  }
            </Box>
          ))
         }
        </Box>
      </Box>
      }
    
</Card>
    </Box>
  )
}
const Follower_Model=()=>{
  
  return(
    <Box>
    <Card sx={{height:'400px',width:'400px',}}>
    <Box sx={{display:'flex',justifyContent:'space-between',padding:'10px'}}>
              <Box sx={{color:'rgb(45, 40, 103)',fontSize:'17px',fontWeight:700,marginTop:'10px'}}>{otherUser && otherUser[0]?.Name !== '' ? otherUser[0]?.Name  : otherUser[0]?.Email }</Box>
            < Box>
            
            </Box>
          </Box>  
          <Box>
            <Box sx={{display:'flex',textAlign:'center',alignItems:'center',justifyContent:'center',borderTop:'3px solid #EAE9F0',background:'rgb(240, 242, 244)'}}>
              <Box sx={{marginTop:'15px',marginBottom:'10px'}}>
                <button  onClick={() => {setFollowerShow(false)}} style={{width:'130px',height:'32px',background: followerShow ? 'rgb(255, 255, 255)' : 'rgb(114, 140, 166)' ,color: followerShow ?  'rgb(114, 140, 166)' : 'rgb(255, 255, 255)' ,borderTopLeftRadius:'3px',borderBottomLeftRadius:'3px',border:'none',fontSize:'14px',fontWeight:700,cursor:'pointer'}}>{followingDetails?.length} Following</button>
                <button onClick={() => {setFollowerShow(true)}}  style={{width:'130px',height:'32px',background:followerShow ?  'rgb(114, 140, 166)' : 'rgb(255, 255, 255)' ,color: followerShow ? 'rgb(255, 255, 255)' : 'rgb(114, 140, 166)',borderTopRightRadius:'3px',borderBottomRightRadius:'3px',border:'none',fontSize:'14px',fontWeight:700,cursor:'pointer'}}>{followerDetails?.length} Followers</button>
              </Box>
            </Box>
          </Box>
          {
            followerShow  === false? <Box sx={{height:'100%',overflow:'scroll',width:'100%'}}>
            {
              followingDetails && followingDetails.map((val,ind) => (
                <Box sx={{display:'flex',justifyContent:'space-between'}}>
                    <Box sx={{display:'flex',padding:'15px',gap:1}} >
                      <Box>
                      <Badge                                
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                       isPremiumActive && ( // Render only if isPremiumActive is true
                                        <Avatar
                                            sx={{ bgcolor: 'red', width: 20, height: 20, fontSize: 10, border: '2px solid white', }}
                                            alt="Remy Sharp"
                                            src={songesss}
                                        >
                                          
                                        </Avatar>
                                          )
                                    }
                                >
                      <Avatar src={val.userData && val.userData[0]?.ProfilePhoto} onClick={() => handleNavigate(val.userData[0]._id)} sx={{width:'45px',height:'45px',borderRadius:'50%',cursor:'pointer'}}/></Badge>
                      </Box>
                        <Box sx={{display:'flex',gap:.3,flexDirection:'column'}}>
                          <Box sx={{fontSize:'14px',fontWeight:700,color:'rgb(45, 40, 103)',overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "140px", // Adjust width as needed
        minWidth: "50px",
        flexShrink: 1,cursor:'pointer'}} onClick={() => handleNavigate(val.userData[0]?._id)}>{val.userData[0]?.Name !== ''? val.userData[0]?.Name  : val.userData[0]?.Email.split("@")[0]}</Box>
                          <Box sx={{fontSize:'14px',fontWeight:400,color:'rgb(114, 140, 166)',width:'160px',overflow:'hidden',textOverflow:'ellipsis'}}>{val.userData && val.userData[0]?.Email}</Box>
                          </Box>
                    </Box>
                    {
                  val.userData[0]._id !== userTokenData.userId &&  
                  <Box sx={{padding:'20px'}}>
                    {
              val.isFollow && true ?   <Box onClick={() => {handleUnfollow(val.FollowingUserID)}}><Button size='small' sx={{fontSize:16,backgroundColor:'rgb(228, 233, 238)',color:'rgb(114, 140, 166)',boxShadow:'rgba(31, 32, 33, 0.1) 0px 2px 8px',fontWeight:700,textTransform:'capitalize'}}  startIcon={<IoMdCheckmark size={14}/>}>
              Following
             </Button></Box> : <Box ><Button size='small' sx={{fontSize:16,backgroundColor:'rgb(108, 46, 255)',color:'white',boxShadow:'rgba(31, 32, 33, 0.1) 0px 2px 8px',fontWeight:700,textTransform:'capitalize'}} variant="contained" startIcon={<GoPlus fill='white' size={18}/>} onClick={() => {handleFollwing(val.FollowingUserID)}}>
             Follow
             </Button></Box>
            }
                    </Box>
                  }
                </Box>
              ))
            }
          </Box> : <Box sx={{overflowY:'scroll',height:'100%'}}>
             {
              followerDetails && followerDetails.map((val,ind) => (
                <Box sx={{display:'flex',justifyContent:'space-between'}}>
                  <Box sx={{display:'flex',gap:2,padding:'10px'}}>
                    <Box>
                    <Badge                                
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                       isPremiumActive && ( // Render only if isPremiumActive is true
                                        <Avatar
                                            sx={{ bgcolor: 'red', width: 20, height: 20, fontSize: 10, border: '2px solid white', }}
                                            alt="Remy Sharp"
                                            src={songesss}
                                        >
                                          
                                        </Avatar>
                                          )
                                    }
                                >
                      <Avatar sx={{width:'45px',height:'45px',borderRadius:'50%',cursor:"pointer"}} src={val.userData && val.userData[0]?.ProfilePhoto} onClick={() => handleNavigate(val.userData[0]._id) }/></Badge>
                    </Box>
                    <Box sx={{display:'flex',flexDirection:'column',marginTop:'5px'}}>
                      <Box sx={{fontSize:'14px',fontWeight:700,color:'rgb(45, 40, 103)',cursor:"pointer",overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "140px", // Adjust width as needed
        minWidth: "50px",
        cursor:'pointer',
        flexShrink: 1,}} onClick={() => handleNavigate(val.userData[0]._id)}>{val.userData && val.userData[0]?.Name !== '' ? val.userData[0]?.Name :val.userData[0]?.Email.split("@")[0] }</Box>
                      <Box sx={{fontSize:'14px',fontWeight:400,color:'rgb(114, 140, 166)',width:'160px',overflow:'hidden',textOverflow:'ellipsis'}}>{val.userData && val.userData[0]?.Email}</Box>
                      </Box>
                  </Box>
                  {
                  val.userData[0]._id !== userTokenData.userId &&  
                  <Box sx={{padding:'20px'}}>
                  {
              val.isFollow && true ?   <Box onClick={() => {handleUnfollow(val.User_ID)}}><Button size='small' sx={{fontSize:16,backgroundColor:'rgb(228, 233, 238)',color:'rgb(114, 140, 166)',boxShadow:'rgba(31, 32, 33, 0.1) 0px 2px 8px',fontWeight:700,textTransform:'capitalize'}}  startIcon={<IoMdCheckmark size={14}/>}>
              Following
             </Button></Box> : <Box ><Button size='small' sx={{fontSize:16,backgroundColor:'rgb(108, 46, 255)',color:'white',boxShadow:'rgba(31, 32, 33, 0.1) 0px 2px 8px',fontWeight:700,textTransform:'capitalize'}} variant="contained" startIcon={<GoPlus fill='white' size={18}/>} onClick={() => {handleFollwing(val.User_ID)}}>
             Follow
             </Button></Box>
            }
                  </Box>
                  }
                </Box>
              ))
             }
          </Box>
          }
        
    </Card>
        </Box>
  )
}
const Edit_Model=()=>{
  
  console.log(bio)
  const handleClick = async () => {
    const formData = new FormData();
    formData.append('ProfileImage',uploadImage)
    formData.append('bio',bio)
    try {
      const config={
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
         const res = await axios.put(`${url}/api/User/updateUser`,formData,config);
         console.log(res)
         window.location.reload()
        
     } catch (error) {
         // Handle errors
         console.log(error)

     }
  }
  return(

    <Box>
    <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
  <Box>
    <Box><Typography fontSize={15}>Edit</Typography></Box>
    <Box><Typography fontSize={11}>Edit profile</Typography></Box>
  </Box>
  
</Box>
<Box>
  <DragDropFileUpload onFileUpload={handleFileUpload}/>
</Box>
<Box>
  <Box sx={{border:"1px solid Blue",p:1,mt:2,borderRadius:2}}>
    <Box sx={{display:"flex",justifyContent:"space-between"}}>
      <Box><Typography fontSize={13} color='blue'>Description</Typography></Box>
      <Box><Typography fontSize={13}>22 Character left</Typography></Box>
    </Box>
    <Box >
     <input placeholder='Singing song' onChange={(e) => {setBio(e.target.value)}} style={{width:250,height:40,outline:"none",border:"none"}}>
     </input>
    </Box>
  </Box>
</Box>
<Box sx={{textAlign:"center"}}>
<IconButton><Button sx={{width:250}} variant='contained' onClick={handleClick}>  Save</Button></IconButton>
</Box>
    </Box>
  )
}
const Share_Model=()=>{
  return(
    <Box>
 <Box>
    <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between",padding:'5px'}}>
  <Box>
    <Box><Typography fontSize={15} fontWeight={700} color={'rgb(45, 40, 103)'}>Share</Typography></Box>
    <Box><Typography fontSize={11} fontWeight={400} color={'rgb(114, 140, 166)'}>Spread the ward</Typography></Box>
  </Box>
  <Box><IconButton><Cancel style={{fill:'rgb(192, 204, 216)'}}/></IconButton></Box>
</Box>
<Divider></Divider>
<Box sx={{display:"flex",justifyContent:"space-around",marginBottom:'10px',padding:'10px'}}>
  <Box sx={{display:"flex",alignItems:"center",background:'#f0f2f4',borderRadius:'5px',marginTop:'15px',marginBottom:'15px',padding:'5px',paddingRight:'15px'}}>
    <Box>
      <IconButton><FacebookIcon style={{fill:'rgb(16 149 244)'}}/></IconButton>
      </Box>
    <Box><Typography>Facebook</Typography></Box>
  </Box>
  <a style={{cursor:'pointer',textDecoration:'none'}} href='https://wa.me/?text=http://99.62.153.141:5555/profile'>
  <Box sx={{display:"flex",alignItems:"center",background:'#f0f2f4',borderRadius:'5px',marginTop:'15px',marginBottom:'15px',padding:'5px',paddingRight:'15px'}}>
    <Box><IconButton><ImWhatsapp color='green'/></IconButton></Box>
    <Box><Typography sx={{color:'rgb(45, 40, 103)',textDecoration:'none'}}>Whats app</Typography></Box></Box>
  </a>
</Box>
    </Box>
    <Box sx={{display:"flex",justifyContent:"flex-start",gap:2,paddingLeft:2}}>
    <Box><Avatar sx={{backgroundColor:"blue"}}><FaTwitter /></Avatar></Box>
    <Box><Avatar><CiLink /></Avatar></Box>
    <Box><Avatar><MdEmail /></Avatar></Box>
    <Box><Avatar><IoIosMore /></Avatar></Box>
    </Box>
    </Box>
  )
}
const [expand,setExpand] = useState(false)
const handleExpand = () => {
      setExpand(true)
      
}

const handleClose = () => {
  setOpen(false)
}
const [fullScreen,setFullScreen] = useState(false)
const [isPremiumActive, setIsPremiumActive] = useState(false);
const handleFullscreen = () => {
   setFullScreen(true)
}
console.log(otherUser)



  console.log(userEmail)
  useEffect(() => {
    const GetWalletPayment = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/Payment/getUserSubAndWallet`, config)
        console.log(res.data, "getWalletBalancesetPayment....")
        // setPayment(res.data)
        setIsPremiumActive(res.data.isPremiumActive);
      } catch (error) {
        console.log(error, 'errrors')
      }
    }
    GetWalletPayment()
  }, [])

  const handleFollow = async () => {
    setFollow(true)
    const config={
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }
    try{
      const request = {
        Name : '',
        FollowingUserID : params.id
      }
      const res = await axios.post(`${url}/api/Following/createFollowing`,request,config)
      console.log(res.data)
    }catch (error){
      console.log(error)
    }
  }
  const [modalContent,setModalContent] = useState(false)
  const [zoomLevel, setZoomLevel] = React.useState(1);
  const handleBlockUser = async () => {
    console.log('clickinnggggggg')
    try{
      const config={
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
      const details ={
        BlockedUserID : params.id
      }
      const res = await axios.post(`${url}/api/BlockUser/createBlockUser`,details,config)
      setModalContent(true)
      console.log(res,'CraeteValuessssss')
    } catch (error){
      console.log(error)
    }
  }

  const handleUnBlockUser = async () => {
       try{
        const config={
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        console.log(config,'configsssss')
          const response = await axios.delete(`${url}/api/BlockUser/deleteBlockUser/${params.id}`,config)
          setModalContent(false)
          console.log(response,'deleteValuessssss')
       } catch (error){
        console.log(error)
       }
  } 
  const handleZoomIn = () => {
    setZoomLevel((prev) => Math.min(prev + 0.2, 3)); // Max zoom level: 3
  };
  
  const handleZoomOut = () => {
    setZoomLevel((prev) => Math.max(prev - 0.2, 1)); // Min zoom level: 1
  };

  return (
    <>
      {
        otherUser === false ? <Errorpage/> :  <>
        {
          otherUser?.length !== 0 ? <Box>
            <Box sx={{background:'rgb(255 255 255)'}}>
            <Container maxWidth='md'>
          <Box sx={{backgroundColor:"#3EB8F9",height:expand ? '' : '200px',borderBottomLeftRadius:10,  backgroundImage: `url(${otherUser[0]?.CoverImage})`,borderBottomRightRadius:10,padding:2,backgroundSize:expand ? '100% 100%' : 'cover',backgroundRepeat:'no-repeat',backgroundPosition:'center'}}>
          <Box sx={{display:'flex',justifyContent:'flex-end',cursor:'pointer'}} onClick={() => setBlockModal(true)}><BsFillFlagFill fill='rgb(255, 255, 255)' size={20}/></Box> 
          {
            otherUser?.length !== 0 && <Box sx={{marginTop:'80px'}} onClick={() => {setOpen(true)}}>
              
              <Badge                                
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                       isPremiumActive && ( // Render only if isPremiumActive is true
                                        <Avatar
                                            sx={{ bgcolor: 'red', width: 35, height: 35, fontSize: 10, border: '2px solid white', }}
                                            alt="Remy Sharp"
                                            src={songesss}
                                        >
                                          
                                        </Avatar>
                                          )
                                    }
                                >
              <Avatar sx={{width:'162px',height:'162px',borderRadius:'50%',marginLeft:'20px',marginTop:'30px',opacity:1,top : expand ? 90 : ''}} src={otherUser[0]?.ProfilePhoto}/></Badge></Box> 
          }    
    </Box>
          <Box sx={{display:"flex",flexDirection:{md:"row",sm:"column",xs:"column"},justifyContent:{md:"space-between",sm:"center",xs:"center"},textAlign:{sm:"center",xs:"center",md:"left"},padding:2,paddingTop:'0'}}>
            <Box sx={{display:"flex",flexDirection:{md:"row",sm:"column",xs:"column"},gap:2,alignItems:"center",justifyContent:"center"}}>
              <Box sx={{display:"flex",mt:12,flexDirection:"column",justifyContent:"center",gap:0.4,fontFamily:"sans-serif"}}>
                <Box><Typography fontSize={14} fontWeight={500} color={'rgb(45, 40, 103)'} >{otherUser[0]?.Name}</Typography></Box>
                <Box><Typography fontSize={14} fontWeight={500} color={'rgb(170, 173, 180)'}>{otherUser[0]?.UserName}</Typography></Box>
                <Box><Typography fontSize={14} fontWeight={500} color={'rgb(45, 40, 103)'} >{otherUser[0]?.Bio}</Typography></Box>
                {/* <Box sx={{display:"flex",color:"#2D2867",gap:1}}>
                  <Box>
                   <TransitionsModal
                    tittle={<Typography color='#2D2867' sx={{textTransform:'none'}} fontSize={15} fontWeight={700}>{count?.length > 1 ? `${otherUser?.length } Recordings`: `${otherUser?.length } Recording` }</Typography>}
                    children={Recording_Model()}
                   />      
                  </Box>
                  <Box>
                  <TransitionsModal
                    tittle={<Typography color='#2D2867' sx={{textTransform:'none'}}  fontSize={15} fontWeight={700}>{otherUser &&otherUser[0]?.followings?.length > 1 ? `${otherUser&&otherUser[0]?.followings?.length} Following`: `${otherUser[0]?.followings?.length} Following` }</Typography>}
                    children={Folowing_Model()}
                   />
                    </Box>
                  <Box>
                  <TransitionsModal
                    tittle={<Typography color='#2D2867' sx={{textTransform:'none'}} fontSize={15} fontWeight={700}>{otherUser[0]?.Followers?.length  > 1 ? `${otherUser[0]?.Followers?.length } Followers`: `${otherUser[0]?.Followers?.length } Follower` }</Typography>}
                    children={Follower_Model()}
                   />
                    </Box>
                  
                </Box> */}
                 <Box sx={{display:"flex",color:"#2D2867",gap:1}}>
                  <Box>
                   <TransitionsModal
                    tittle={<Typography color='#2D2867' sx={{textTransform:'none'}} fontSize={15} fontWeight={700}>{count?.RecordingResult > 1
            ? `${count?.RecordingResult} Recordings`
            : `${count?.RecordingResult} Recording`}</Typography>}
                    children={Recording_Model()}
                   />      
                  </Box>
                  <Box>
                  <TransitionsModal
                    tittle={<Typography color='#2D2867' sx={{textTransform:'none'}} fontSize={15} fontWeight={700}>{count?.FollowingResult > 1
            ? `${count?.FollowingResult} Following`
            : `${count?.FollowingResult} Following`}</Typography>}
                children={Folowing_Model() }
                   />
                    </Box>
                  <Box>
                  <TransitionsModal
                    tittle={<Typography color='#2D2867' sx={{textTransform:'none'}}  fontSize={15} fontWeight={700}>{count?.FollowerResult > 1
            ? `${count?.FollowerResult} Followers`
            : `${count?.FollowerResult} Follower`}</Typography>}
                     children={Follower_Model()}
                   />
                    </Box>
                  
                  
                </Box>
                <Box><Typography fontSize={15} fontWeight={400} color={'rgb(45, 40, 103)'}>{otherUser.length !== 0 != '' ? otherUser[0].UserProfileNote : 'born on 2002' }</Typography></Box>
                
              </Box>
            </Box>
            <Box sx={{display:"flex",justifyContent:"center",gap:2}}>
              <Box sx={{marginTop:'6px'}}>   
                  {
                    followerDetails.some(e => e.User_ID === userTokenData.userId) === true || follow ? <Button sx={{padding:'5px',background:'rgb(228, 233, 238)',fontSize:'15px',fontWeight:700,boxShadow:'rgba(31, 32, 33, 0.1) 0px 2px 8px',color:'rgb(114, 140, 166)',textTransform:'none'}}>Following</Button> : <Button onClick={handleFollow} sx={{background:'rgb(108, 46, 255)',fontSize:'15px',fontWeight:700,boxShadow:'rgba(31, 32, 33, 0.1) 0px 2px 8px',color:'white',padding:'5px 10px'}}>+ Follow</Button>
                  }
              </Box>
              <Box>
              <Box> 
                <TransitionsModal
                 tittle={<Button sx={{backgroundColor:"#2D2867",color:"white",fontWeight:'bold',fontSize:'17px'}} size='small' variant="outlined" startIcon={<FaShare />}>
                 Share
               </Button>}
               children={Share_Model()}
                />
              </Box>
              </Box>
            </Box>
          </Box>
          <Divider sx={{ bgcolor: 'rgb(228, 233, 238)', height:2,borderRadius:'2px' }} />
          </Container>
            </Box>
          <OtherProfileTab mixSong={otherUser}/>
          
        </Box> : <Box sx={{background:'rgb(255 255 255)'}}>
          <Container maxWidth='md'>
          <Skeleton animation='wave' variant='rounded' height={200}/>
          <Box sx={{display:'flex',justifyContent:'space-between'}}>
            <Box>
            <Skeleton sx={{opacity:1,position:'absolute',top:190,marginLeft:'30px'}} variant='circular' animation='wave' width={162} height={162}/>
            </Box>
            <Box sx={{display:'flex',gap:1,marginTop:'10px'}}>
              <Skeleton variant='rounded' width={75} height={30}/>
              <Skeleton variant='rounded' width={75} height={30}/>
            </Box>
          </Box>
          <Skeleton sx={{marginTop:'60px'}} width={200}/>
          <Skeleton sx={{marginTop:'10px'}} width={200}/>
          <Box sx={{display:'flex',gap:2,marginTop:'10px'}}>
            <Box sx={{display:'flex',gap:.5}}>
              <Skeleton variant='rounded' width={40} />
              <Skeleton variant='rounded' width={120} />
            </Box>
            <Box sx={{display:'flex',gap:.5}}>
              <Skeleton variant='rounded' width={40} />
              <Skeleton variant='rounded' width={120} />
            </Box>
            <Box sx={{display:'flex',gap:.5}}>
              <Skeleton variant='rounded' width={40} />
              <Skeleton variant='rounded' width={120} />
            </Box>
          </Box>
          <Skeleton sx={{marginTop:'10px'}}/>
          <Divider sx={{marginTop:'40px',marginBottom:'20px',bgcolor: 'rgb(228, 233, 238)', height:2,borderRadius:'2px' }} />
          <Box sx={{display:'flex',gap:4,paddingBottom:'20px'}}>
            <Box>
              <Skeleton variant='rounded'  width={60} height={10}/>
            </Box>
            <Box>
              <Skeleton variant='rounded' width={60} height={10}/>
            </Box>
            <Box>
              <Skeleton variant='rounded' width={60} height={10}/>
            </Box>
            <Box>
              <Skeleton variant='rounded' width={60} height={10}/>
            </Box>
            <Box>
              <Skeleton variant='rounded' width={60} height={10}/>
            </Box>
            <Box>
              <Skeleton variant='rounded' width={60} height={10}/>
            </Box>
          </Box>
          </Container>
          
        </Box>
        }
          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={{ width: fullScreen ? '100%' : '80%', background: '#000000', height: '100%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
                <Box onClick={() => {
                  setOpen(false)
                  setFullScreen(false)
                }} sx={{ cursor: 'pointer' }}>
                  <CloseIcon style={{ color: 'white', fontSize: '30px' }} />
                </Box>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Box sx={{ cursor: 'pointer' }}
                  onClick={handleZoomIn}
                  >
                    <ZoomInIcon style={{ color: 'white', fontSize: '40px' }} />
                  </Box>
                  <Box sx={{ cursor: 'pointer' }}
                  onClick={handleZoomOut}
                  >
                    <ZoomOutIcon style={{ color: 'white', fontSize: '40px' }} />
                  </Box>
                  {
                    fullScreen ? <Box sx={{ cursor: 'pointer' }} onClick={() => { setFullScreen(false) }}>
                      <CloseFullscreenIcon style={{ color: 'white', fontSize: '40px' }} />
                    </Box> : <Box sx={{ cursor: 'pointer' }} onClick={handleFullscreen}>
                      <AiOutlineFullscreen size={37} color='white' />
                    </Box>
                  }
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', height: fullScreen ? '90%' : '80%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', height: fullScreen ? '90%' : '80%' }}>
              <img 
                src={otherUser.length !== 0 ? otherUser[0]?.CoverImage : img} 
                style={{ 
                  objectFit: 'contain', 
                  padding: '60px', 
                  transform: `scale(${zoomLevel})`, 
                  transition: 'transform 0.3s ease-in-out',
                  mt:20, 
                }} 
              />
            </Box>
              </Box>
            </Box>
            {
              fullScreen ? '' : <Box sx={{ width: '20%', height: '100%', background: 'white' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '15px', paddingBottom: '30px' }}>
                    <Box sx={{ display: 'flex', gap: .5 }}>
                      <Box>
                        <img style={{ width: '40px', height: '40px', borderRadius: '50%' }} src={otherUser[0]?.ProfilePhoto} />
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '4px' }}>
                        <Box sx={{ fontSize: '14px', fontWeight: 700 }}>{otherUser[0]?.Name !== '' ? otherUser[0]?.Name : otherUser[0]?.Email}</Box>
                        <Box sx={{ color: 'rgb(201 212 222)', fontSize: '14px' }}>15 d</Box>
                      </Box>
                    </Box>
                    <Box><MoreHoriz /></Box>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
                    <Box sx={{ display: 'flex', gap: .5 }}>
                      <Box><ThumbUp style={{ color: '#0566ff', fontSize: '20px' }} /></Box>
                      <Box sx={{ color: '#65676b', fontSize: '15px' }}>20</Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: .5 }}>
                      <Box><BiSolidMessageRounded style={{ color: '#606770', fontSize: '20px' }} /></Box>
                      <Box sx={{ color: '#606770', fontSize: '15px' }}>20</Box>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', padding: '10px 20px', gap: 1, borderTop: '1px solid #ced0d4', borderBottom: '1px solid #ced0d4', justifyContent: 'space-between' }}>
                    <Box><ThumbUp style={{ color: '#606770', fontSize: '20px' }} /></Box>
                    <Box><BiSolidMessageRounded style={{ color: '#606770', fontSize: '20px' }} /></Box>
                    <Box><FaWhatsapp style={{ color: '#606770', fontSize: '20px' }} /></Box>
                    <Box><PiShareFatLight style={{ color: '#606770', fontSize: '20px' }} /></Box>
                  </Box>
                </Box>
              </Box>
            }
          </Box>
        </Box>
      </Modal>
          {/* <Modal
            open={blockModal}
            onClose={() => {setBlockModal(false)}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style1}>
              <Box sx={{display:'flex',flexDirection:'column',justifyContent:'flex-start'}}>
                <Box sx={{display:'flex',justifyContent:'space-between',margin:'10px',alignItems:'center'}}>
                <Box sx={{color:'rgb(45, 40, 103)',fontSize:'17px',fontWeight:700}}>Options</Box>
                <Box><MdCancel cursor={"pointer"} size={25} fill='rgb(192 204 216)'onClick={() => {setBlockModal(false)}}/></Box>
                </Box>
                <Divider/>
                {
                  modalContent ?  <Box sx={{color:'rgb(45, 40, 103)',margin:'15px',fontSize:'17px',fontWeight:700,cursor:'pointer'}} onClick={handleUnBlockUser}>Unblock User</Box> : 
                  <Box sx={{color:'rgb(45, 40, 103)',margin:'15px',fontSize:'17px',fontWeight:700,cursor:'pointer'}} onClick={handleBlockUser}>Block User</Box>
                }
                <Divider/>
              <Box sx={{color:'rgb(45, 40, 103)',margin:'15px',fontSize:'17px',fontWeight:700,cursor:'pointer'}} onClick={() => {navigate('/help')}}>Flag User</Box>
              </Box>
            </Box>
          </Modal> */}
           
        </>
       }
    </>
  )
}

export default OtherProfileHeader;