import React, { useEffect, useState } from "react";
import { Box, Container, Card, Select,MenuItem,Modal,Backdrop,CircularProgress, Typography, Button } from "@mui/material";
import logo from '../assest/images/logo1.jpeg';
import axios from "axios";
import { url } from "../config/config";
import { useNavigate, useParams } from "react-router-dom";
import TimeAgo from "../pages/recordings/timeStmaps";
import { CheckCircle, HourglassEmpty } from "@mui/icons-material";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };


const SongCollabEdit = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [collabData,setCollabData] = useState([])
    const [age, setAge] = React.useState(10);
    const [inputData,setInputData] = useState('')
    const [img,setImg] = useState(null);
    const [open,setOpen] = useState(false)
    const [save,setsave]= React.useState(false)
    const[ responseDesign,setResponseDesign]=useState(true)
    useEffect(() => {
        const SongCollab = async () => {
            try{
              const config={
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
              }
                const res = await axios.get(`${url}/api/SongCollaboration/getSongCollaborationById/${params.id}`,config)
                console.log(res.data)
                setCollabData(res.data.data)
                setInputData(res.data.data[0]?.Description)
            }catch (error){
                console.log(error)
            }
        }
        SongCollab()
    },[])
    console.log(collabData[0]?.CoverImageURL)
    const handleChange = (event) => {
        setAge(event.target.value);
      };
      const handleImg = (e) => {
         console.log(e.target.files[0])
         setImg(e.target.files[0])
      }

      const Url = img !== null ? URL.createObjectURL(img) : '';
      console.log(Url)    
      
      const handleSave = async () => {
        setsave(true)
        try{
            // const data = {
            //     coverImage : img !== null ? img : collabData[0]?.CoverImageURL,
            //     Description : inputData
            // }
            const config={
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
              }
            const formData = new FormData();
            formData.append('coverImage',img)
            formData.append('Description',inputData)
            const res = await axios.put(`${url}/api/SongCollaboration/updateSongCollaborationCoverImage/${params.id}`,formData,config)
            console.log(res.data)
            setResponseDesign(false)
        }catch (error){
          console.log(error)
        }
      }

      const handleClose = () => {
        setOpen(false)
        setsave(false)
      }

      const handleDelete = async () => {
           try{
            const config={
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
              }
            const res = await axios.post(`${url}/api/SongCollaboration/deleteSongCollaboration/${params.id}`,config)
            console.log(res)
           }catch (error){
            console.log(error)
           }
      }

      

    return(
        <Container maxWidth='md'>
            <Card sx={{marginTop:'30px',backgroundColor:"#5751FE",color:"white"}}>
                <Box sx={{display:'flex',justifyContent:'center',padding:'20px',borderBottom:'1px solid #e8e8e8'}}>
                    <Box sx={{display:'flex',gap:1}}>
                        <Box>
                            <img  style={{height:'48px',width:'48px'}} src={logo}/>
                        </Box>
                        <h1 style={{fontSize:'2em',fontWeight:300,lineHeight:'1.6em',margin:'0',fontWeight:"bold"}}>Edit Recording</h1>
                    </Box>
                </Box>
                <Box sx={{display:'flex',padding:'20px',gap:4}}>
                <input onChange={handleImg} className="inputfile" type="file" name="file" id="file"/>
                <label for='file'>
                <Box sx={{backgroundImage: Url !== '' ? `url(${Url})` : `url(${collabData[0]?.CoverImageURL && collabData[0]?.CoverImageURL})`,cursor:'pointer',backgroundSize:'cover',width:220,height:220}}>
                             <Box sx={{textAlign:'center',alignItems:'center',justifyContent:'center',fontSize:'1em',fontWeight:700,padding:'10px',background:'rgba(0, 0, 0, 0.6)',color:'white'}}>Update Art</Box>
                     </Box>
                </label>
                     <Box sx={{display:'flex',flexDirection:'column',width:570,color:'white'}}>
                        <Box sx={{display:'flex',gap:3,paddingBottom:'20px'}}>
                            <Box sx={{fontSize:'15px',fontWeight:400,fontWeight:"bold"}}>Song name</Box>
                            <Box sx={{color:'light-dark(rgb(84, 84, 84)',fontSize:'1em'}}>{collabData[0]?.karaokes && collabData[0].karaokes[0].Name}</Box>
                        </Box>
                        <Box sx={{display:'flex',gap:4,paddingBottom:'20px'}}>
                            <Box sx={{fontSize:'1em',fontWeight:400,fontWeight:"bold"}}>Message</Box>
                            <Box sx={{width:'100%'}}>
                                <textarea type="textarea" value={inputData}  onChange={(e) => {setInputData(e.target.value)}} maxLength={250}  className="textFiled" style={{width:'100%',border:'1px solid #e8e8e8',padding:'0',height:60}}/>
                            </Box>
                        </Box>
                        <Box sx={{display:'flex',gap:5}}>
                            <Box sx={{fontSize:'1em',fontWeight:400,fontWeight:"bold"}}>Privacy</Box>
                            <Box sx={{color:"white"}}>
                            <Select
          onChange={handleChange}
          value={age}
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{
            width: 480,
            height: '40px',
            color: 'white',
            borderColor: 'black', // Changed to black
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'black', // Ensures black border is applied
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'black', // Maintains black border on hover
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'black', // Keeps black border when focused
            },
          }}
        >
          <MenuItem value={10}>Public - everyone can this recording</MenuItem>
          <MenuItem value={20}>Privacy - only you can see this recording</MenuItem>
        </Select>
                            </Box>
                        </Box>
                     </Box>
                </Box>
                <Box sx={{display:'flex' ,justifyContent:'right',padding:'20px',borderBottom:'1px solid #e8e8e8'}}>
                    <Box sx={{display:'flex',gap:3}}>
                        <Box sx={{color:'white',padding:"1",fontSize:'15px',fontWeight:700,cursor:'pointer'}} onClick={() => {navigate(`/record/${params.id}`)}}>Cancel</Box>
                        
                        <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={save}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 1000 } }}
    >
      <Box sx={style}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          {responseDesign ? (
            <>
            
              <HourglassEmpty sx={{ fontSize: 40, color: '#1dc97d' }} />
              
              <Typography variant="h6" fontWeight="700">
                Updating Details!
              </Typography>
              <CircularProgress />
            </>
          ) : (
            <>
              <CheckCircle sx={{ fontSize: 40, color: '#1dc97d' }} />
              <Typography variant="h6" fontWeight="700">
                Successfully Updated!
              </Typography>
              <Button
                variant="contained"
                color="success"
                onClick={() => navigate(`/record/${params.id}`)}
                sx={{ mt: 2 }}
              >
                Okay!
              </Button>
            
            </>
          )}
        </Box>
      </Box>
    </Modal>
          <Box
  sx={{
    padding:1,
    color: 'white',
    fontSize: '15px',
    fontWeight: 700,
    background: '#1ED760',
    height: '20px',
    width: '50px',
    textAlign: 'center',
    borderRadius: '3px',
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: "#1dc97d"
    },
  }}
  onClick={handleSave}
>
  Save
</Box>
                    </Box>
                </Box>
                <Box sx={{display:'flex',justifyContent:'right',padding:'20px'}}>
                    <Box sx={{display:'flex',gap:3}}>
                        <Box sx={{fontSize:'15px',fontWeight:400,color:'white'}}><TimeAgo timestamp={collabData && collabData[0]?.createdAt}/></Box>
                        <Box sx={{color:'#000',padding:1,fontSize:'15px',fontWeight:700,background:'#ecb9b9',height:'20px',width:'60px',textAlign:'center',borderRadius:'3px',cursor:'pointer', "&:hover": {
      backgroundColor: "#ED5E68",color:"white"
    },}} onClick={() => {setOpen(true)}}>Delete</Box>
                    </Box>
                </Box> 
            </Card>
            <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
            <Box>Are sure you want to delete ?</Box>
            <Box sx={{color:'#000',fontSize:'15px',fontWeight:700,background:'#ecb9b9',height:'20px',width:'60px',textAlign:'center',borderRadius:'3px',cursor:'pointer',padding:'10px'}} onClick={handleDelete}>Confrim</Box>
          </Box>
        </Box>
      </Modal>
        </Container>
    )
}

export default SongCollabEdit;