import { Avatar, Badge, Box, Button, CircularProgress, Container, Modal, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import girl from '../../assest/images/girl.webp'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { url } from '../../config/config';
import TimeAgo from '../recordings/timeStmaps';
import { Cancel } from '@mui/icons-material';
import Appcard from '../../components/app-card';
import { ImAppleinc } from 'react-icons/im';
import image3 from '../../assest/images/NewLogo.png'
import songesss from '../../assest/images/premiumIcon.png'

const Search = () => {
    const [songcollaboration, setSongCollaboration] = useState([])
    const userTokanValue = localStorage.getItem('token');
    const [songs, setSongs] = useState([])
    const [user, setUser] = useState([])
    const [invites, setInvites] = useState([])
    const [isPremiumActive, setIsPremiumActive] = useState(false);
    const [IsPremiumuser, setIsPremiumuser] = useState([])
    const [groups, setgroups] = useState([])
    const [loading, setloading] = useState(true)
    const [open, setOpen] = useState(false);
    const params = useParams();
    console.log(params, "johnpetter");
    const handleOpen = () => {
        setOpen(true); // Open modal
    };

    const handleClose = () => {
        setOpen(false); // Close modal
    };
    const handleNavigation = (val) => {
        console.log(val, 'before value');
        navigate(`/searchPagination/${val}/${params.value}`,);
    };

    const navigate = useNavigate()
    useEffect(() => {
        const searchResult = async () => {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                }
                const res = userTokanValue !== null ? await axios.get(`${url}/api/GlobalFilter/getGlobalFilter/${params.value}`, config)
                    : await axios.get(`${url}/api/GlobalFilter/getGlobalOtherFilter/${params.value}`)
                console.log(res.data.data, 'SearchDataValuesssssssss')
                setSongCollaboration(res.data.data[1])
                setIsPremiumuser(res.data.data[2][0]?.isPremium);
                 console.log(res.data.data[2][0]?.isPremium,"...................",);
                 
                // if (IsPremiumuser === true) {
                //     <Avatar
                //         sx={{ bgcolor: 'red', width: 35, height: 35, fontSize: 10, border: '2px solid white' }}
                //         alt="Premium"
                //         src={songesss}
                //     />
                // }



                // console.log(res.data.data[2].followings.isPremium,"johnnjhiv");


                setSongs(res.data.data[0])
                setUser(res.data.data[2], "inveyyttttt")
                setInvites(res.data.data[3], "inveyyttttt")
                setgroups(res.data.data[4], "inveyyttttt")

            } catch (error) {
                console.log(error, 'errorsearchResult')
            }
            finally {

                setloading(false);
            }
        }
        searchResult()
    }, [params.value])
    songcollaboration && songcollaboration.map((val) => (
        console.log(val.users.length)
    ))
    console.log(user)
    useEffect(() => {
        const GetWalletPayment = async () => {
            try {
                const config = {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                }
                const res = await axios.get(`${url}/api/Payment/getUserSubAndWallet`, config)
                console.log(res.data, "getWalletBalancesetPayment....")
                // setPayment(res.data)
                setIsPremiumActive(res.data.isPremiumActive);
            } catch (error) {
                console.log(error, 'errrors')
            }
        }
        GetWalletPayment()
    }, [])
    return (

        <Container style={{ backgroundColor: 'white', minHeight: '100vh' }}>

            <Box pt={4}>
                <Box>
                    <Box><Typography fontSize={30} color={"#673EF7"} fontWeight={"bold"}>{`Showing results for "${params.value}"`}</Typography></Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {
                        loading ? <Box sx={{ marginTop: 20 }}>
                            <CircularProgress />

                        </Box> : null
                    }
                </Box>
                <Box mt={5}>
                {songcollaboration?.length > 0 ? (
        <>
                    {
                        songcollaboration.length > 0 ? <Box sx={{ display: "flex", justifyContent: "space-between", backgroundColor: "#E9ECEC", padding: 2, cursor: 'pointer' }}>
                            <Box><Typography fontWeight={"bold"}>Recordings</Typography></Box>
                            <Box onClick={() => handleNavigation(1)} sx={{ cursor: "pointer" }}><Typography color="green" fontWeight={700}>See All</Typography></Box>
                        </Box> : null
                    }
                    {
                        songcollaboration && songcollaboration.map((data, index) => (
                            index < 6 ? <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 2, cursor: 'pointer' }}>

                                <><Box key={index} sx={{ display: "flex", gap: 1 }}>
                                    <Button onClick={() => {
                                        navigate(`/record/${data._id}`)
                                    }}><Avatar
                                            alt="Remy Sharp"
                                            src={data.CoverImageURL}
                                            sx={{ width: 60, height: 60 }}
                                            variant='square' /></Button>
                                    <Box sx={{ marginTop: '5px' }}>
                                        <Box sx={{ pt: 0 }}><Typography fontSize={'1em'} fontWeight={700}>{data.karaokes[0].Name}</Typography></Box>
                                        <Box><Typography fontSize={9} style={{ width: 100, color: '#aaa', fontSize: '1em', fontWeight: 400, ':hover': { color: '#1ED760' } }}>{data.users[0].Name}</Typography></Box>
                                        <Box sx={{ display: "flex", alignItems: "center", gap: .5, ':hover': { color: '#1ED760' } }}>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                <Box sx={{ fontSize: 9 }}><PlayArrowIcon sx={{ fontSize: '13px', color: '#cfcfcf' }} /></Box>
                                                <Box sx={{ fontSize: '13px', color: '#cfcfcf' }}>{data.NoOfPlay}</Box>
                                            </Box>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                <Box sx={{ fontSize: 9 }}><FavoriteIcon sx={{ fontSize: '13px', color: '#cfcfcf' }} /></Box>
                                                <Box sx={{ fontSize: '13px', color: '#cfcfcf' }}>{data.NoOfLikes}</Box>
                                            </Box>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                <Box sx={{ fontSize: 9 }}><ChatBubbleIcon sx={{ fontSize: '13px', color: '#cfcfcf' }} /></Box>
                                                <Box sx={{ fontSize: '13px', color: '#cfcfcf' }}>{data.NoOfComments}</Box>
                                            </Box>

                                        </Box>
                                    </Box>
                                </Box><Box>
                                        {
                                            data.users.length > 1 ? <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                                                <Box><Typography sx={{ fontSize: '11px', color: '#636363' }}>Recorded by</Typography></Box>
                                                <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                                                    <Box><Avatar alt="Remy Sharp" src={girl} /></Box>
                                                    <Box><Typography fontSize={13} fontWeight={600}>_WaNhi_</Typography></Box>
                                                </Box>

                                            </Box> <Box><Typography>+</Typography></Box> <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                                                    <Box><Typography sx={{ fontSize: '11px', color: '#636363' }}>Recorded by</Typography></Box>
                                                    <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                                                        <Box><Avatar alt="Remy Sharp" src={girl} /></Box>
                                                        <Box><Typography fontSize={13} fontWeight={600}>_WaNhi_</Typography></Box>
                                                    </Box>

                                                </Box></Box> : <Box onClick={() => { navigate(`/otherProfile/${data.users[0]?._id}`) }} sx={{ display: 'flex', gap: 1 }}>
                                                <Box sx={{ fontSize: '11px', color: '#636363', marginTop: '10px' }}>Recorded by</Box>
                                                <Box>
                                                    <Avatar src={data.users[0].ProfilePhoto} />
                                                </Box>
                                                <Box sx={{ color: '#636363', fontSize: '11px', marginTop: '10px' }}>
                                                    {data.users[0].Name !== '' ? data.users[0].Name : data.users[0].Email}
                                                </Box>
                                            </Box>
                                        }
                                    </Box><Box><Typography fontSize={13}>Recorded  <TimeAgo timestamp={data.createdAt} /></Typography></Box></>
                            </Box> : ''

                        ))
                    }
                        </>
    ) : (
        // Show this if invites are empty
       
         <Box>
         <Box sx={{ display: "flex", justifyContent: "space-between", backgroundColor: "#E9ECEC", padding: 2 }}>
                             <Box><Typography fontWeight={"bold"}>Recordings</Typography></Box>
                         
                         </Box>
         
     <Box sx={{ textAlign: "center", padding: 2, color: "#aaa" }}>
         <Typography>No recordings available</Typography>
     </Box></Box>
    )}
                    
                </Box>
                
                <Box>
                    <Box pt={2}>
                    {songs?.length > 0 ? (
        <>
                        {
                            songs.length > 0 ? <Box sx={{ display: "flex", justifyContent: "space-between", backgroundColor: "#E9ECEC", padding: 2 }}>
                                <Box><Typography fontWeight={"bold"}>Songs</Typography></Box>
                                <Box onClick={() => { handleNavigation(0) }} sx={{ cursor: "pointer" }}><Typography color="green" fontWeight={700}>See All</Typography></Box>
                            </Box> : null
                        }
                        <Box sx={{ display: "flex", flexWrap: 'wrap', whiteSpace: 'nowrap', padding: '15px', gap: 2 }}>
                            {
                                songs && songs.slice(0, 6).map((val, ind) => (
                                    ind < 6 ?
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                                            <Box sx={{ display: 'flex', gap: 2, cursor: "pointer" }} onClick={() => { navigate(`/allsong/${val._id}`) }} >
                                                <Box>
                                                    <Avatar
                                                        alt="Remy Sharp"
                                                        src={val.CoverImageURL}
                                                        sx={{ width: 60, height: 60 }}
                                                        variant='square' />
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: .5, width: '400px' }}>
                                                    <Box sx={{ color: '#000', fontSize: '12px', fontWeight: 700 }}>{val.Name}</Box>
                                                    {/* <Box sx={{ color: '#aaa', fontSize: '12px' }}>Hi-Fi</Box> */}
                                                    <Box sx={{ color: '#aaa', fontSize: '12px', ':hover': { color: '#1ED760' } }}>{val.users[0]?.UserName}</Box>
                                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                                        <Avatar
                                                            alt="Remy Sharp"
                                                            src={val.users[0]?.ProfilePhoto}
                                                            sx={{ width: 20, height: 20 }}
                                                            variant="circular" />
                                                        {/* <Box><AccountCircleIcon style={{ color: '#aaa' }} /></Box> */}
                                                        <Box sx={{ color: '#aaa', fontSize: '12px', ':hover': { color: '#1ED760' } }}>{val.users[0]?.Name}</Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: "flex", textAlign: "center", gap: 3, marginTop: '10px', cursor: 'pointer', height: 20 }}>
                                                <Box
                                                    onClick={() => { navigate(`/allsong/${val._id}`) }}

                                                    sx={{
                                                        background: 'none',
                                                        border: '1px solid black',
                                                        padding: '5px 10px',
                                                        height: 20,
                                                        borderRadius: '5px',
                                                        cursor: 'pointer',
                                                        transition: 'all 0.3s ease',
                                                        ':hover': {
                                                            background: '#1ED760',
                                                            color: 'black'
                                                        }
                                                    }}
                                                >
                                                    Lyrics
                                                </Box>
                                                <Box

                                                    sx={{
                                                        background: 'none',
                                                        border: '1px solid black',
                                                        padding: '5px 10px',
                                                        height: 20,
                                                        borderRadius: '5px',
                                                        cursor: 'pointer',
                                                        transition: 'all 0.3s ease',
                                                        ':hover': {
                                                            background: '#1ED760',
                                                            color: 'black'
                                                        }
                                                    }} onClick={handleOpen}
                                                >
                                                    {loading ? <CircularProgress size={24} /> : "Sing"}
                                                </Box>
                                            </Box>
                                        </Box> : ""
                                ))
                            }
                        </Box>
                        </>
    ) : (
        // Show this if invites are empty
       
         <Box>
         <Box sx={{ display: "flex", justifyContent: "space-between", backgroundColor: "#E9ECEC", padding: 2 }}>
                             <Box ><Typography fontWeight={"bold"}>Songs</Typography></Box>
                         
                         </Box>
         
     <Box sx={{ textAlign: "center", padding: 2, color: "#aaa" }}>
         <Typography>No songs available</Typography>
     </Box></Box>
    )}
                    </Box>
                    
                </Box>
                <Box pt={2}>
                {user?.length > 0 ? (
        <>

                    {
                        user.length > 0 ? <Box sx={{ display: "flex", justifyContent: "space-between", backgroundColor: "#E9ECEC", padding: 2 }}>
                            <Box><Typography fontWeight={"bold"}>Users</Typography></Box>
                            <Box onClick={() => { handleNavigation(2) }} sx={{ cursor: "pointer" }}><Typography color="green" fontWeight={700}>See All</Typography></Box>
                        </Box> : null
                    }
                    <Box sx={{ display: "flex", gap: 6 }}>
                        {
                            user && user.map((data, index) => (
                                index < 6 ?
                                    <Box onClick={() => { navigate(`/otherProfile/${data._id}`) }} key={index} sx={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "left", padding: '15px', gap: 2, cursor: 'pointer' }}>
                                        <Box>
                                            <Box>
                                                {user && user.isPremium ? (
                                                    console.log(user.isPremium, "teyufududv"),

                                                    <Badge
                                                        overlap="circular"
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        badgeContent=
                                                        
                                                            {IsPremiumuser && (
                                                                <Avatar
                                                                    sx={{ bgcolor: 'red', width: 35, height: 35, fontSize: 10, border: '2px solid white' }}
                                                                    alt="Premium"
                                                                    src={songesss}
                                                                />
                                                            )}
                                                        

                                                    >
                                                        <Avatar
                                                            sx={{ height: '100px', width: '100px', border: '3px solid gold' }}
                                                            src={user?.ProfilePhoto || ''}
                                                        />
                                                    </Badge>
                                                ) : (
                                                    <Avatar sx={{ height: '100px', width: '100px', border: '3px solid #528000', }} src={user?.ProfilePhoto || ''} />
                                                )}
                                            </Box>
                                        </Box>
                                        <Box sx={{
                                            color: "black", "&:hover": {
                                                color: "#5FC28D", // Change this to your desired hover color
                                            },
                                        }}><Typography fontSize={13} fontWeight={700}>{data.Name}</Typography></Box>

                                    </Box> : ''
                            ))
                        }
                    </Box>
                    </>
    ) : (
        // Show this if invites are empty
        
         <Box>
         <Box sx={{ display: "flex", justifyContent: "space-between", backgroundColor: "#E9ECEC", padding: 2 }}>
                             <Box><Typography fontWeight={"bold"}>User</Typography></Box>
                         
                         </Box>
         
     <Box sx={{ textAlign: "center", padding: 2, color: "#aaa" }}>
         <Typography>No user available</Typography>
     </Box></Box>
    )}
                </Box>
                <Box pt={2}>
                    {/* <Box sx={{display:"flex",justifyContent:"space-between",backgroundColor:"#E9ECEC",padding:2}}>
                <Box><Typography>Invites</Typography></Box>
                <Box onClick={() => {handleNavigation('invites')}}  sx={{cursor:"pointer"}}><Typography color="green" fontWeight={700}>see all</Typography></Box>
            </Box>
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",paddingTop:2}}>
                <Box sx={{display:"flex",gap:1}}>
                    <Box><Avatar
                        alt="Remy Sharp"
                        src={girl}
                        sx={{ width: 60, height: 60 }}
                        variant='square'
/></Box>
                    <Box>
                    <Box sx={{pt:0}}><Typography fontSize={11} fontWeight={700}>(5min) Ye waqt na kho jaaye</Typography></Box>
                        <Box pt={0.5}><Typography fontSize={9}>,ᴴᴰ full ye waqt na kho jaaye, Main tum mein samaa jaaun</Typography></Box>
                        <Box sx={{display:"flex",alignItems:"center",gap:2,paddingTop:0.5}}>
                            <Box sx={{display:"flex",alignItems:"center",gap:1}}>
                                <Box sx={{fontSize:9}}><PlayArrowIcon sx={{fontSize:'13px',color:'#cfcfcf'}}/></Box>
                                <Box sx={{fontSize:'13px',color:'#cfcfcf'}}>1</Box>
                            </Box>
                            <Box sx={{display:"flex",alignItems:"center",gap:1}}>
                                <Box sx={{fontSize:9}}><FavoriteIcon sx={{fontSize:'13px',color:'#cfcfcf'}}/></Box>
                                <Box sx={{fontSize:'13px',color:'#cfcfcf'}}>23</Box>
                            </Box>
                            <Box sx={{display:"flex",alignItems:"center",gap:1}}>
                                <Box sx={{fontSize:9}}><ChatBubbleIcon sx={{fontSize:'13px',color:'#cfcfcf'}}/></Box>
                                <Box sx={{fontSize:'13px',color:'#cfcfcf'}}>22</Box>
                            </Box>
                         
                        </Box>
                    </Box>
                </Box>
                <Box sx={{display:"flex",gap:2,alignItems:"center"}}>
                    <Box><Typography fontSize={13}>Created by</Typography></Box>
                    <Box sx={{display:"flex",gap:0.5,alignItems:"center"}}>
                        <Box><Avatar alt="Remy Sharp" src={girl} /></Box>
                        <Box><Typography fontSize={13} fontWeight={600}>_WaNhi_</Typography></Box>
                    </Box>
                    
                </Box>
                <Box>
                    <Box><Button sx={{backgroundColor:"Highlight",width:50,height:25,fontSize:6,color:"white"}}>Join Now</Button></Box>
                    <Box sx={{display:"flex",alignItems:"center"}}>
                        <Box><Typography><AccessTimeIcon sx={{fontSize:15}}/></Typography></Box>
                        <Box><Typography fontSize={11}>4 days left</Typography></Box>
                    </Box>
                </Box>
            </Box> */}
                    
                        {/* <Box sx={{display:"flex",gap:1}}>
                    <Box><Avatar
                        alt="Remy Sharp"
                        src={girl}
                        sx={{ width: 60, height: 60 }}
                        variant='square'
/></Box>
                    <Box>
                    <Box sx={{pt:0}}><Typography fontSize={11} fontWeight={700}>(5min) Ye waqt na kho jaaye</Typography></Box>
                        <Box pt={0.5}><Typography fontSize={9}>,ᴴᴰ full ye waqt na kho jaaye, Main tum mein samaa jaaun</Typography></Box>
                        <Box sx={{display:"flex",alignItems:"center",gap:2,paddingTop:0.5}}>
                            <Box sx={{display:"flex",alignItems:"center",gap:1}}>
                                <Box sx={{fontSize:9}}><PlayArrowIcon sx={{fontSize:17}}/></Box>
                                <Box sx={{fontSize:8}}>1</Box>
                            </Box>
                            <Box sx={{display:"flex",alignItems:"center",gap:1}}>
                                <Box sx={{fontSize:9}}><FavoriteIcon sx={{fontSize:15}}/></Box>
                                <Box sx={{fontSize:8}}>23</Box>
                            </Box>
                            <Box sx={{display:"flex",alignItems:"center",gap:1}}>
                                <Box sx={{fontSize:9}}><ChatBubbleIcon sx={{fontSize:15}}/></Box>
                                <Box sx={{fontSize:8}}>22</Box>
                            </Box>
                         
                        </Box>
                    </Box>
                </Box> */}
                        <Box>
                            {/* john.................................. */}
                            <Box pt={2}>
                            {invites?.length > 0 ? (
        <>
                                {
                                    invites && invites.length > 0 ? <Box sx={{ display: "flex", justifyContent: "space-between", backgroundColor: "#E9ECEC", padding: 2 }}>
                                        <Box><Typography fontWeight={"bold"}>invites</Typography></Box>
                                        <Box onClick={() => { handleNavigation(3) }} sx={{ cursor: "pointer" }}><Typography color="green" fontWeight={700}>See All</Typography></Box>
                                    </Box> : null
                                }
                                <Box sx={{ display: "flex", flexWrap: 'wrap', whiteSpace: 'nowrap', padding: '15px', gap: 2 }}>
                                    {
                                        invites && invites.map((val, ind) => (
                                            console.log(val, "datadddd....."),

                                            ind < 6 ?
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                                        <Box>
                                                            <Avatar
                                                                alt="Remy Sharp"
                                                                src={val.karaokes[0].CoverImageURL}
                                                                sx={{ width: 60, height: 60 }}
                                                                variant='square' />
                                                        </Box>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: .5, width: '400px' }}>
                                                            <Box sx={{ color: '#000', fontSize: '12px', fontWeight: 700 }}>{val.karaokes[0].Name}</Box>
                                                            <Box sx={{ color: '#aaa', fontSize: '12px' }}>{val.karaokes[0].Artist}</Box>
                                                            <Box sx={{ display: 'flex' }}>
                                                                <Box><AccountCircleIcon style={{ color: '#aaa' }} /></Box>
                                                                <Box sx={{ color: '#aaa', fontSize: '12px' }}>jj</Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ marginTop: '10px', cursor: 'pointer' }}>
                                                        <button onClick={() => { navigate(`/allsong/${val._id}`) }} style={{ background: 'none', border: '1px solid black', padding: '5px 10px', borderRadius: '5px', cursor: 'pointer' }}>Lyrics</button>
                                                    </Box>
                                                </Box> : ""
                                        ))
                                    }
                                </Box>
                                   </>
    ) : (
        // Show this if invites are empty
        
        <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", backgroundColor: "#E9ECEC", padding: 2 }}>
                            <Box><Typography fontWeight={"bold"}>invites</Typography></Box>
                        
                        </Box>
        
    <Box sx={{ textAlign: "center", padding: 2, color: "#aaa" }}>
        <Typography>No invites available</Typography>
    </Box></Box>
    )}
                            </Box>
                            {/* <Box mt={5}>
           {
            invites.length>0 ? <Box sx={{display:"flex",justifyContent:"space-between",backgroundColor:"#E9ECEC",padding:2,cursor:'pointer'}}>
                <Box><Typography>Recordings</Typography></Box>
                <Box onClick={() => handleNavigation(3)}  sx={{cursor:"pointer"}}><Typography color="green" fontWeight={700}>See All</Typography></Box>
            </Box>:null
           }
            {
                       invites && invites.map((data,index) => (
                        index < 6 ?   <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",paddingTop:2,cursor:'pointer'}}>
              
                        <><Box key={index} sx={{ display: "flex", gap: 1 }}>
                               <Button  onClick={()=>{
          navigate(`/allsong/${data._id}`)
        }}><Avatar
                                   alt="Remy Sharp"
                                   src={data.karaokes[0].CoverImageURL}
                                   sx={{ width: 60, height: 60 }}
                                   variant='square' /></Button>
                               <Box sx={{marginTop:'5px'}}>
                                   <Box sx={{ pt: 0 }}><Typography  fontSize={'1em'} fontWeight={700}>{data.karaokes[0].Name}</Typography></Box>
                                   <Box><Typography fontSize={9} style={{width:100,color:'#aaa',fontSize:'1em',fontWeight:400,':hover': {color: '#1ED760' }}}>{data.users[0].Name}</Typography></Box>
                                   <Box sx={{ display: "flex", alignItems: "center",gap:.5,':hover': {color: '#1ED760' }}}>
                                       <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                           <Box sx={{ fontSize: 9 }}><PlayArrowIcon sx={{fontSize:'13px',color:'#cfcfcf'}} /></Box>
                                           <Box sx={{fontSize:'13px',color:'#cfcfcf'}}>{data.NoOfPlay}</Box>
                                       </Box>
                                       <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                           <Box sx={{ fontSize: 9 }}><FavoriteIcon sx={{fontSize:'13px',color:'#cfcfcf'}} /></Box>
                                           <Box sx={{fontSize:'13px',color:'#cfcfcf'}}>{data.NoOfLikes}</Box>
                                       </Box>
                                       <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                           <Box sx={{ fontSize: 9 }}><ChatBubbleIcon sx={{fontSize:'13px',color:'#cfcfcf'}}/></Box>
                                           <Box sx={{fontSize:'13px',color:'#cfcfcf'}}>{data.NoOfComments}</Box>
                                       </Box>

                                   </Box>
                               </Box>
                           </Box><Box>
                            {
                                data.users.length > 1 ?  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                                       <Box><Typography sx={{fontSize:'11px',color:'#636363'}}>Recorded by</Typography></Box>
                                       <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                                           <Box><Avatar alt="Remy Sharp" src={girl} /></Box>
                                           <Box><Typography fontSize={13} fontWeight={600}>_WaNhi_</Typography></Box>
                                       </Box>

                                   </Box> <Box><Typography>+</Typography></Box> <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                                           <Box><Typography sx={{fontSize:'11px',color:'#636363'}}>Recorded by</Typography></Box>
                                           <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                                               <Box><Avatar alt="Remy Sharp" src={girl} /></Box>
                                               <Box><Typography fontSize={13} fontWeight={600}>_WaNhi_</Typography></Box>
                                           </Box>

                                       </Box></Box> : <Box onClick={() => {navigate(`/otherProfile/${data.users[0]?._id}`)}} sx={{display:'flex',gap:1}}>
                                    <Box sx={{fontSize:'11px',color:'#636363',marginTop:'10px'}}>Recorded by</Box>
                                    <Box>
                                        <Avatar src={data.users[0].ProfilePhoto}/>
                                    </Box>
                                    <Box sx={{color:'#636363',fontSize:'11px',marginTop:'10px'}}>
                                        {data.users[0].Name !== '' ? data.users[0].Name : data.users[0].Email}
                                    </Box>
                                </Box> 
                            }     
                               </Box><Box><Typography fontSize={13}>Recorded  <TimeAgo timestamp={data.createdAt}/></Typography></Box></>
            </Box> : ''
          
        ))
                }
        </Box> */}
                        </Box>
                        {/* <Box sx={{display:"flex",gap:2,alignItems:"center"}}>
                    <Box><Typography fontSize={13}>Created by</Typography></Box>
                    <Box sx={{display:"flex",gap:0.5,alignItems:"center"}}>
                        <Box><Avatar alt="Remy Sharp" src={girl} /></Box>
                        <Box><Typography fontSize={13} fontWeight={600}>_WaNhi_</Typography></Box>
                    </Box>
                    
                </Box> */}
                        {/* <Box>
                    <Box><Button sx={{backgroundColor:"Highlight",width:50,height:25,fontSize:6,color:"white"}}>Join Now</Button></Box>
                    <Box sx={{display:"flex",alignItems:"center"}}>
                        <Box><Typography><AccessTimeIcon sx={{fontSize:15}}/></Typography></Box>
                        <Box><Typography fontSize={11}>4 days left</Typography></Box>
                    </Box>
                </Box> */}
                    </Box>

                </Box>
                <Box mt={1}>
                {invites?.length > 0 ? (
        <>
                    {
                        groups && groups.length > 0 ?
                            <Box sx={{ display: "flex", justifyContent: "space-between", backgroundColor: "#E9ECEC", padding: 2 }}>
                                <Box><Typography fontWeight={"bold"}>Groups</Typography></Box>
                                <Box onClick={() => { handleNavigation(4) }} sx={{ cursor: "pointer" }}>
                                    <Typography color="green" fontWeight={700}>See All</Typography>
                                </Box>
                            </Box> : null}
                    <Box sx={{ display: "flex", gap: 4, mt: 2 }}>
                        {groups && groups.slice(0, 6).map((group, index) => (
                            <Box key={index} sx={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "left" }}>
                                <Box>
                                    <Avatar
                                        alt={group.ChannelName}
                                        src={group.CoverImage}
                                        sx={{ width: 150, height: 150 }}
                                        variant="square"
                                        onClick={() =>
                                            navigate(`/othergroupinfo/${group._id}`, { state: group })
                                        }
                                    />
                                </Box>
                                <Box sx={{ maxWidth: 150, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                    <Typography fontSize={13} fontWeight={700} sx={{ textAlign: 'center', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {group.ChannelName}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <Box>{group.MemberUserIDsCount} members</Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
                        <Box
                            sx={{
                                width: '600px',
                                backgroundColor: '#f9f9f9',
                                padding: 4,
                                margin: 'auto',
                                position: 'absolute',
                                top: '40%',
                                left: '50%',
                                transform: 'translate(-50%, -30%)',
                                borderRadius: 2,
                                boxShadow: 24,
                            }}
                        >
                            <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
                                <Cancel onClick={handleClose} style={{ cursor: 'pointer' }} />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                                <Box>
                                    <img src={image3} alt="App Preview" style={{ width: 180, height: 'auto' }} />
                                </Box>
                                <Box>
                                    <Typography id="modal-title" variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                        Get SparkSinger
                                    </Typography>
                                    <Typography id="modal-description" variant="body1" sx={{ mb: 3 }}>
                                        Sing and be part of the SparkSinger community!
                                    </Typography>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        <Appcard icon={<ImAppleinc fontSize="large" />} tittle1="Download on the" tittle2="App Store" />
                                        <Appcard icon={<ImAppleinc fontSize="large" />} tittle1="Download on the" tittle2="Google Play" />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Modal>
                    </>
    ) : (
        // Show this if invites are empty
        <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", backgroundColor: "#E9ECEC", padding: 2 }}>
                                <Box><Typography fontWeight={"bold"}>Groups</Typography></Box>
                            
                            </Box>
            
        <Box sx={{ textAlign: "center", padding: 2, color: "#aaa" }}>
            <Typography>No groups available</Typography>
        </Box></Box>
    )}
                </Box>
                
          
        </Container>

    )
}

export default Search