import React, { useState } from 'react';
import { Box, Typography, Button, Modal } from '@mui/material';
import "./Styles/app.css";

const Addpage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    setIsClosing(false); // Reset closing state when opening
  };

  const closeModal = () => {
    setIsClosing(true); // Set closing state
    setTimeout(() => setIsModalOpen(false), 1000); // Wait for animation to finish before hiding modal
  };

  return (
    <Box>
      <Box>
        <Typography variant="h6">
          iuityufiftdytdytdytdteijciejcj eicheuhceuceuc
        </Typography>
        <Button variant="contained" onClick={openModal}>Open Modal</Button>
      </Box>
      

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        closeAfterTransition
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: 3,
            borderRadius: 2,
            boxShadow: 24,
            transformOrigin: 'top center',
          }}
          className={isClosing ? 'bookClose' : 'bookOpen'}
        >
          <Typography id="modal-title" variant="h6">
            Hello, I am a modal!
          </Typography>
          <Typography id="modal-description">
            This is some content inside the modal.
          </Typography>
          <Button onClick={closeModal} sx={{ marginTop: 2 }}>Close</Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default Addpage;