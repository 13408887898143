import { Avatar, Box, Modal, Typography } from '@mui/material'
import React, { useState } from 'react'
import girl from '../../../assest/images/girl.webp'
import { deepOrange, deepPurple } from '@mui/material/colors';
import RedoIcon from '@mui/icons-material/Redo';
import MicExternalOnIcon from '@mui/icons-material/MicExternalOn';
import { FaEdit } from "react-icons/fa";
import ModalComponent from './module';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { url } from '../../../config/config';
import Appcard from '../../../components/app-card';
import { ImAppleinc } from 'react-icons/im';
import { Cancel } from '@mui/icons-material';
import image3 from "../../../assest/images/NewLogo.png"
import Spark from '../../spark';
import { BsGooglePlay } from 'react-icons/bs';
const Header = ({songlyric}) => {
  console.log(songlyric)
  const [isModalOpen,setIsModalOpen] = useState(false)
  const [modalContent,setModalContnet] = useState('')
  const [modalType,setModalType] = useState('');
  const [modalLink,setModalLink] = useState('');
  const [open, setOpen] = useState(false);
  const params = useParams();
  const handleOpen = () => {
    setOpen(true); // Open modal
  };

  const handleClose = () => {
    setOpen(false); // Close modal
  };

  const OpenModal = (val,data,link) => {
    console.log(val,data)
    setIsModalOpen(true)
    setModalContnet(val)
    setModalType(data)
    setModalLink(link)
    
  }
  const closeModal = () => {
    setIsModalOpen(false)
    setModalContnet('')
    setModalType('')
    
  }
 
  return (
    <Box>
    <>
  <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",gap:2,alignItems:"center"}}>
        <Box>
           {
            songlyric.length>0? <img src={songlyric[0]['CoverImageURL']} style={{width:"120px",height:"120px",borderRadius:8}}></img>:""
           }
        </Box>
        <Box>
            {/* <Box><Typography fontSize={13} sx={{color:"#2D2867"}}>Anne-Marie</Typography></Box>
            <Box><Typography fontSize={25} sx={{color:"#2D2867"}}> Heavy</Typography></Box>
            <Box><Typography fontSize={10} sx={{color:"#2D2867"}}>Published by
ANNEMARIE

</Typography></Box> */}
<Box sx={{display:"flex",flexDirection:"column",gap:2,marginTop:4}}>
  
<Box onClick={() => OpenModal('Spread the word','share',`${window.location.href}`)}><Avatar sx={{ backgroundColor:"#2D2867",width:50,height:50}}><RedoIcon/></Avatar>
<Box><Typography fontSize={16} marginLeft={1}>Share</Typography></Box>
</Box>
<Box>
      
      <Box>
        <Typography
          fontSize={16}
          marginLeft={0}
          onClick={handleOpen} // Add onClick event
          style={{ cursor: 'pointer' }} // Add cursor style to indicate it's clickable
        >
          <Avatar sx={{ backgroundColor: "#6C2EFF", width: 50, height: 50 }}>
        <MicExternalOnIcon />
      </Avatar>
      <Typography>Sing </Typography>
        </Typography>
      </Box>

      {/* Modal */}
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
    <Box
      sx={{
        width: '600px',
        backgroundColor: '#f9f9f9',
        padding: 4,
        margin: 'auto',
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -30%)',
        borderRadius: 2,
        boxShadow: 24,
      }}
    >
      <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
        <Cancel onClick={handleClose} style={{ cursor: 'pointer' }} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <Box>
          <img src={image3} alt="App Preview" style={{ width: 180, height: 'auto' }} />
        </Box>
        <Box>
          <Typography id="modal-title" variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
            Get the SparkSinger App
          </Typography>
          <Typography id="modal-description" variant="body1" sx={{ mb: 3 }}>
            Sing your favorite songs and immerse yourself in a vibrant community of music lovers with the  SparkSinger app - download it today from your app store!
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 ,cursor:"pointer"}}>
            <Appcard icon={<ImAppleinc fontSize="large" />} tittle1="Download on the" tittle2="App Store" />
            <Appcard icon={<BsGooglePlay fontSize="large" />} tittle1="Download on the" tittle2="Google Play" />
          </Box>
        </Box>
      </Box>
    </Box>
  </Modal>
    </Box>
</Box>
        </Box>
    </Box>
    <ModalComponent modalLink={modalLink} isOpen={isModalOpen} onClose={closeModal} content={modalContent} type={modalType}/>
   
    </>
   
    </Box>
  )
}

export default Header