import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  OutlinedInput,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { url } from '../config/config';
import logo from '../assest/images/Bright Color-01.png'
import { useLocation } from 'react-router-dom';

const Forgot = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [resetEmail, setResetEmail] = React.useState('');
  const [forpass, setForpass] = React.useState('');
  const [reset, setReset] = React.useState('');
  const [showResetPasswordModal, setShowResetPasswordModal] = React.useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const token = queryParams.get('token');

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev); // Toggle true/false
  };
  const handleResetPassword = async () => {
    console.log('UserEmail: ', resetEmail, 'UserPassword :', forpass);
 
    try {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
      const res = await axios.post(`${url}/api/User/forgotPassword`, {
        UserEmail:resetEmail,
        UserPassword:forpass,
      }, config);
      console.log(res)

    } catch (error) {
      // Handle errors
      console.log(error)

    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        };
        const res = await axios.get(`${url}/api/User/forgotPasswordEmail?token=${token}`, config);
        console.log(res.data.data.Email, 'forgetemailtoken');
        setResetEmail(res.data.data.Email);
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }, [token]);
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: '#32286F',
        padding: 3,
        
      }}
    >
      <Box
        sx={{
          width: '400px',
          textAlign: 'center',
          backgroundColor: 'black',
          padding: 3,
          borderRadius: '10px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
          
        }}
      >
          <Box sx={{ width: '250px', margin: '0 auto' }}>
                <img src={logo} alt="Logo" />
              </Box>
        <Typography
          fontWeight="bold"
          fontSize={25}
          color="white"
          fontFamily="Cursive"
          mt={2}
        >
          Reset Password
        </Typography>
        <Typography
          fontSize={14}
          color="white"
          fontFamily="Cursive"
          mt={1}
        >
          Enter your email address to receive a password reset link.
        </Typography>

       
        <Box sx={{ mt: 3 }}>
          <InputBase
            sx={{
              color: 'white',
              width: '100%',
              borderRadius: '3px',
              padding: '10px 20px',
              border: 1,
              borderColor: '#6f6f6f',
              '&:hover': {
                borderColor: 'white',
              },
            }}
            placeholder="New password"
            value={reset}
            onChange={(e) => setReset(e.target.value)}
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <FormControl sx={{ width: '100%' }} variant="outlined">
            <OutlinedInput
              placeholder="Confirm Password"
              id="outlined-adornment-password"
              sx={{
                backgroundColor: '#121212',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#6F6F6F',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#FFFFFF',
                },
                '& .MuiInputBase-input': {
                  color: 'white',
                },
              }}
              type={showPassword ? 'text' : 'password'}
              value={forpass}
              onChange={(e) => setForpass(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      showPassword ? 'hide the password' : 'display the password'
                    }
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <Visibility sx={{ color: 'white' }} />
                    ) : (
                      <VisibilityOff sx={{ color: 'white' }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 3,
            mt: 3,
          }}
        >
          <Button
            variant="outlined"
            sx={{
              color: 'white',
              borderColor: 'white',
              borderRadius: 20,
              '&:hover': {
                borderColor: '#1ED760',
              },
            }}
           
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#1ED760',
              color: 'black',
              borderRadius: 20,
              '&:hover': {
                backgroundColor: '#1EB460',
              },
            }}
            onClick={handleResetPassword}
          >
            Submit Now
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Forgot;
