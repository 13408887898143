import { Box, Container } from '@mui/material'
import React, { createContext, useRef } from 'react'
import Header from './components/header'
import Footersongs from './components/footer-songs'
// import globe from './components/globe.jpg'
import world from "../../assest/images/world.png"

 export const AudioContext = createContext();

const Recording = () => {
  const audioRef  = useRef();
  return (
    <Box sx={{backgroundImage:`url(${world})`,backgroundColor:'#DEF1FD',backgroundPosition:'center',backgroundSize:'contain',backgroundRepeat:"no-repeat"}}>
      <AudioContext.Provider value={audioRef}>
      <Container maxWidth='lg' sx={{minHeight:"100vh"}}>
     <Box padding={1} >
        <Box ><Header/></Box>
        <Box marginTop={6}><Footersongs/></Box>
    </Box>
   </Container>
      </AudioContext.Provider>
   </Box>
  )
}

export default Recording